<!-- Ce composant permet à l'utilisateur de choisir une basemap (fond de carte) -->
<template>
  <v-card flat width="450px">
    <v-list style="padding: 0">
      <!-- 1ere basemap : fond de carte OSM (openstreetmap) -->
      <v-list-item link @click="setBasemap('classic')">
        <v-list-item-content style="padding: 0; padding-top: 3px">
          <v-row>
            <!-- Image -->
            <v-col cols="5">
              <v-img
                id="osm-basemap"
                height="92px"
                width="92px"
                :src="require('@/assets/images/basemap/osm.png')"
                :lazy-src="
                  require('@/assets/images/basemap/osm.png')
                "
                class="rounded-circle basemap-selected"
              />
            </v-col>
            <!-- Section descriptive -->
            <v-col cols="7">
              <!-- Titre -->
              <p style="font-weight: bold; text-align: left">Flux OSM</p>
              <!-- Description -->
              <p style="text-align: left; font-size: small">
                Fond de carte communautaire et Open-Source
              </p>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>

      <!-- 2e basemap : fond de carte Orthophotographique de GéograndEst 2019 -->
      <!--  Voir au-dessus pour la description interne -->
      <v-list-item link @click="setBasemap('ortho')">
        <v-list-item-content style="padding: 0; padding-top: 3px">
          <v-row>
            <v-col cols="5">
              <v-img
                id="ortho-basemap"
                height="92px"
                width="92px"
                :src="require('@/assets/images/basemap/ortho.png')"
                :lazy-src="
                  require('@/assets/images/basemap/ortho.png')
                "
                class="rounded-circle"
              />
            </v-col>
            <v-col cols="7">
              <p style="font-weight: bold; text-align: left">Flux Orthophotographique 2018/2019</p>
              <p style="text-align: left; font-size: small">
                Fond de carte produit et mis à disposition par DataGrandEst (Millésime: 2018/2019)
              </p>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>

      <!-- 3e basemap : fond de carte Orthophotographique de GéograndEst 2010 -->
      <!--  Voir au-dessus pour la description interne -->
      <v-list-item link @click="setBasemap('ortho-2010')">
        <v-list-item-content style="padding: 0; padding-top: 3px">
          <v-row>
            <v-col cols="5">
              <v-img
                id="ortho-2010-basemap"
                height="92px"
                width="92px"
                :src="require('@/assets/images/basemap/ortho-2010.png')"
                :lazy-src="
                  require('@/assets/images/basemap/ortho-2010.png')
                "
                class="rounded-circle"
              />
            </v-col>
            <v-col cols="7">
              <p style="font-weight: bold; text-align: left">Flux Orthophotographique 2007-2010</p>
              <p style="text-align: left; font-size: small">
                Fond de carte produit et mis à disposition par DataGrandEst (Millésime: 2007-2010)
              </p>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: 'BasemapSelector',
  methods: {
    // Emet un évènement pour changer la basemap de la carte + surligne l'image correspondant à la basemap sélectionnée
    setBasemap(value) {
      // Si l'utilisateur sélectionne la valeur "ortho"
      if (value === "ortho") {
        // Supprime la classe sélective de la basemap OSM
        document.getElementById('osm-basemap').classList.remove("basemap-selected")
        // Ajout la classe sélective à la basemap Ortho
        document.getElementById('ortho-basemap').classList.add("basemap-selected")
        document.getElementById('ortho-2010-basemap').classList.remove("basemap-selected")
      // Sinon, si l'utilisateur sélectionne la value Classic
      } else if (value === "classic") {
        // Voir ci-dessus
        document.getElementById('osm-basemap').classList.add("basemap-selected")
        document.getElementById('ortho-basemap').classList.remove("basemap-selected")
        document.getElementById('ortho-2010-basemap').classList.remove("basemap-selected")
      // Sinon, si l'utilisateur sélectionne la valeur ortho-2010
      } else if (value === "ortho-2010") {
        document.getElementById('osm-basemap').classList.remove("basemap-selected")
        document.getElementById('ortho-basemap').classList.remove("basemap-selected")
        document.getElementById('ortho-2010-basemap').classList.add("basemap-selected")
      }
      // Emet un évènement sur la modification de la basemap
      this.$emit('changeBasemap', value)
    }
  }
}
</script>

<style scoped>
.basemap-selected {
  border: 3px solid black
}
</style>