<!-- Composant principal de l'application : Affiche une carte OpenLayers avec des données ainsi que deux panels de sélection et/ou d'affichage -->
<template>
  <div id="explore-capture" style="height: 100%; position: relative">
    <!-- Barre d'outil à gauche de la carte -->
    <v-navigation-drawer
      mini-variant
      absolute
      class="d-print-none"
      permanent
      style="height: 100%; z-index: 99"
    >
      <!-- Liste de navigation -->
      <v-list nav rounded>
        <!-- Outil "rechercher" -->
        <v-list-item
          style="margin-top: 12px"
          dense
          link
          @click="displaySearch()"
        >
          <v-list-item-action style="margin: 0">
            <!-- Permet d'afficher un tooltip sur un élément -->
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <!-- Icône à afficher -->
                <v-icon v-bind="attrs" v-on="on" :color="searchIsOpen ? '#0075ce' : ''">mdi-magnify</v-icon>
              </template>
              <!-- Texte à afficher dans le tooltip -->
              <span>Rechercher</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
        <!-- Outil "Paramètres de la carte" (Voir ci-dessus pour le détail de l'item) -->
        <v-list-item
          style="margin-top: 12px"
          dense
          link
          @click="displayPopup()"
        >
          <v-list-item-action style="margin: 0">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" :color="settingsIsOpen ? '#0075ce' : ''">mdi-cog</v-icon>
              </template>
              <span>Paramètres de la carte</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>

        <!-- Active le click sur la carte -->
        <v-list-item
          style="margin-top: 12px"
          dense
          link
          @click="checkToggleInfos()"
          v-if="!infos"
        >
          <v-list-item-action style="margin: 0">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-if="!infos" v-bind="attrs" v-on="on">mdi-map-marker-question</v-icon>
              </template>
              <span>Informations sur des éléments de carte</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
        <v-list-item
          style="margin-top: 12px"
          dense
          link
          @click="checkToggleInfos()"
          v-if="infos"
        >
          <v-list-item-action style="margin: 0">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="#0075ce" v-bind="attrs" v-on="on">mdi-map-marker-question</v-icon>
              </template>
              <span>Informations sur des éléments de carte</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
        <!-- Outil de légende (Voir ci-dessus pour le détail de l'item) -->
        <v-list-item
          style="margin-top: 12px"
          dense
          link
          @click="displayLegend()"
        >
          <v-list-item-action style="margin: 0">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" :color="legendIsShown ? '#0075ce' : ''">mdi-format-list-bulleted-square</v-icon>
              </template>
              <span>Légende</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
        <!-- Permet de revenir à la vue du territoire choisi -->
        <v-list-item
          style="margin-top: 12px"
          dense
          link
          @click="rezoom()"
        >
          <v-list-item-action style="margin: 0">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-arrow-expand-all</v-icon>
              </template>
              <span>Revenir au territoire observé</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
        <!-- Permet de revenir à la région -->
        <v-list-item
          style="margin-top: 12px"
          dense
          link
          @click="reloadRegion()"
        >
          <v-list-item-action style="margin: 0">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-arrow-collapse</v-icon>
              </template>
              <span>Revenir à l'échelle de la Région</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <!-- Explore div, display a full-screen map and stats -->
    <div class="explore-div" id="explore-div">
      <!-- Container affichant les informations sur l'élément cliqué -->
      <div id="popup" :class="swipeIsActivated ? 'ol-popup ol-popup-swipe' : 'ol-popup'" transition="scroll-y-transition">
        <v-container>
          <v-card flat>
            <v-row>
              <v-col class="pa-0 ma-0 mt-5 ml-2">
                <v-btn icon class="ol-popup-closer" @click="deleteFeature">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <div id="popup-content">
                  <h3 class="mb-2">OCS GE2 - {{ overlayContent.year }}</h3>
                  <v-divider/>
                  <div v-if="!overlayContent.errorBool">
                    <p class="ma-0"><b>{{ overlayContent.code1 }}</b> : {{ overlayContent.niv1 }}</p>
                    <p class="ma-0 ml-2" v-if="this.levelSelected >= 2"><b>{{ overlayContent.code2 }}</b> : {{ overlayContent.niv2 }}</p>
                    <p class="ma-0 ml-4" v-if="this.levelSelected >= 3"><b>{{ overlayContent.code3 }}</b> : {{ overlayContent.niv3 }}</p>
                    <p class="ma-0 ml-6" v-if="this.levelSelected >= 4"><b>{{ overlayContent.code4 }}</b> : {{ overlayContent.niv4 }}</p>
                    <p class="ma-0" v-if="this.levelSelected >= 5"><b>{{ overlayContent.code5 }}</b> : {{ overlayContent.niv5 }}</p>
                  </div>
                  <div v-else>
                    <p class="ma-0">{{ overlayContent.error }}</p>
                  </div>
                  <v-divider/>
                  <p class="ma-0 mt-2"><b>Surface</b> : {{ parseFloat(overlayContent.area).toFixed(2) }} ha</p>
                </div>
              </v-col>
              <v-col v-if="swipeIsActivated" class="pa-0 ma-0 mt-5 mr-2">
                <v-btn icon class="ol-popup-closer" @click="deleteFeature">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <div id="popup-content">
                  <h3 class="mb-2">OCS GE2 - {{ overlayContent2.year }}</h3>
                  <v-divider/>
                  <div v-if="!overlayContent2.errorBool">
                    <p class="ma-0"><b>{{ overlayContent2.code1 }}</b> : {{ overlayContent2.niv1 }}</p>
                    <p class="ma-0 ml-2" v-if="this.levelSelected >= 2"><b>{{ overlayContent2.code2 }}</b> : {{ overlayContent2.niv2 }}</p>
                    <p class="ma-0 ml-4" v-if="this.levelSelected >= 3"><b>{{ overlayContent2.code3 }}</b> : {{ overlayContent2.niv3 }}</p>
                    <p class="ma-0 ml-6" v-if="this.levelSelected >= 4"><b>{{ overlayContent2.code4 }}</b> : {{ overlayContent2.niv4 }}</p>
                    <p class="ma-0" v-if="this.levelSelected >= 5"><b>{{ overlayContent2.code5 }}</b> : {{ overlayContent2.niv5 }}</p>
                  </div>
                  <div v-else>
                    <p class="ma-0">{{ overlayContent2.error }}</p>
                  </div>
                  <v-divider/>
                  <p class="ma-0 mt-2"><b>Surface</b> : {{ parseFloat(overlayContent2.area).toFixed(2) }} ha</p>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </div>
      <!-- Composant de recherche -->
      <Search
        ref="searchComponent"
        @citySelected="citySearched"
        @closeDial="disableSearchColor"
        :map="map"
      ></Search>
      <!-- Composant de Légende -->
      <Legend
        :zoom="zoom"
        ref="legendComponent"
        @hideLegend="displayLegend()"
        @showLastLevel="goToLastLevel()"
        :levelSelected="levelSelected"
      ></Legend>
      <!-- Composant concernant le paramétrage de la carte -->
      <Popup
        ref="popupComponent"
        :swipe="swipeIsActivated"
        @changeMapStyle="updateMapLayers"
        @changeBasemap="setBasemap($event)"
        @updateOpacity="setOpacity($event)"
        @updateOpacity2="setOpacity2($event)"
        @changeLevel="setLevel($event)"
        @closeDial="disablePopupColor"
      ></Popup>

      <!-- Bouton permettant d'afficher le drawer de droite si celui-ci est fermé -->
      <v-btn
        absolute
        tile
        color="white"
        v-if="!drawer"
        @click="toggledDrawer"
        x-large
        class="explore-drawer-button"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <!-- Drawer de droite : affiche différentes statistiques -->
      <Details
        :cityHistorized="cityHistorized"
        :cityType="cityType"
        :currentCity="currentCity"
        :currentCityStat="currentCityStat"
        :drawer="drawer"
        :map="map"
        :elementSelected="citySelected"
        :cityPopulation="cityPopulation"
        @updateScale="newStat"
        @print="print"
        @citySelected="citySearched"
        @dateChanged="changeDate"
        @dateChanged2="changeDate2"
        @changeMapStyle="updateMapLayers"
        @updateCityType="updateCityType"
        @toggledDrawer="toggledDrawer"
        @activate-swipe="activateSwipe"
        @disable-swipe="disableSwipe"
      ></Details>

      <!-- Pop-up s'affichant si l'utilisateur clique sur "imprimer" -->
      <v-dialog v-model="printDialog" width="500">
        <v-card color="blue">
          <!-- Titre -->
          <v-card-title> Avertissement </v-card-title>
          <!-- Description -->
          <v-card-text>
            La fenêtre d'impression de votre navigateur va s'ouvrir (le visuel
            sera différent selon le navigateur internet utilisé). Vous pouvez
            sélectionner les paramètres souhaités pour obtenir un meilleur
            résultat. Pour un rendu optimal, nous vous conseillons d'utiliser
            l'outil "capture d'écran" de votre ordinateur.
          </v-card-text>
          <!-- Trait gris faisant la séparation entre le texte et le bouton -->
          <v-divider></v-divider>
          <!-- Bouton permettant l'ouverture de la fenêtre d'impression du navigateur -->
          <v-card-actions>
            <!-- Occupe tout l'espace disponible à gauche du bouton -->
            <v-spacer></v-spacer>
            <v-btn text @click="printWindow"> Je comprends </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <!-- Dialog affichant des informations lors du premier lancement de l'info-bulle -->
    <v-dialog v-model="tuto" v-if="!this.infos" persistent max-width="374">
      <TutoEntity @closeDial="closeTutoDialog"/>
    </v-dialog>

    <Maintenance />
  </div>
</template>

<script>
// Importons nos composants
import Search from "@/components/Search/Search";
import Details from "@/components/Explore/Details";
import Popup from "@/components/Explore/Popup";
import Maintenance from "@/components/Maintenance/Maintenance.vue";
import Legend from "@/components/Explore/Legend";
import TutoEntity from "@/components/Explore/TutoEntity.vue"
// Importons les composants nécessaire au fonctionnement d'openlayers
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import { fromLonLat, transform, toLonLat, transformExtent } from "ol/proj";
import proj4 from "proj4";
import { register } from "ol/proj/proj4";
import XYZ from "ol/source/XYZ";
import { ScaleLine, defaults as defaultControls } from "ol/control";
import WMTS, { optionsFromCapabilities } from "ol/source/WMTS";
import WMTSCapabilities from "ol/format/WMTSCapabilities";
import Overlay from 'ol/Overlay';
// Importons les outils nous permettant de dessiner les features
import GeoJSON from 'ol/format/GeoJSON';
import {Vector as VectorSource} from 'ol/source';
import {Vector as VectorLayer} from 'ol/layer';
// Importons de quoi styliser la feature à afficher
import {Fill, Stroke, Style} from 'ol/style';
// Importons axios pour réaliser nos requêtes
import axios from "axios";
// Importons le swipe
import Swipe from 'ol-ext/control/Swipe';

export default {
  name: "Explore",
  components: {
    Details,
    Legend,
    Maintenance,
    Popup,
    Search,
    TutoEntity
  },
  data() {
    return {
      // Basemap de la carte
      basemap: null,
      // Label de la carte
      basemapLabel: null,
      // Garde la ville sélectionnée en mémoire pour pouvoir y retourner lors d'un changement d'échelle
      cityHistorized: {
        id: null,
        niveau: null
      },
      cityPopulation: null,
      // Déclare si une ville est sélectionnée ou pas
      citySelected: false,
      // Type de ville sélectionnée
      cityType: "commune",
      // Ville sélectionnée
      currentCity: null,
      // Statistiques de la ville sélectionnée
      currentCityStat: null,
      // Date sélectionnée
      dataToLoad: "2019",
      // Deuxième date sélectionnée pour le swipe
      dataToLoad2: "2010",
      // Affiche ou non le drawer de droite
      drawer: true,
      // Permet d'activer ou non le click sur la map
      infos: false,
      legendIsShown: false,
      // Gère le niveau de nomenclature
      levelSelected: 1,
      // Affiche ou non le niveau 5 de la nomenclature (représente un comportement particulier)
      level_5: false,
      // Affiche les différentes limites administratives
      limitesCommunesLayer: "",
      limitesEpciLayer: "",
      limitesScotLayer: "",
      limitesPnrLayer: "",
      limitesDepartementLayer: "",
      // La carte
      map: null,
      // Déclare si la carte est chargée ou non
      mapLoaded: false,
      // Notre layer de données
      ourLayer: undefined,
      ourLayer2: undefined,
      // Ajoute un overlay sur la carte
      overlay: undefined,
      // Gère le contenu de l'overlay
      overlayContent: {},
      overlayContent2: {},
      // Affiche ou non le popup d'impression
      printDialog: false,
      searchIsOpen: false,
      settingsIsOpen: false,
      // Swipe control
      swipeControl: undefined,
      swipeIsActivated: false,
      tuto: false,
      // Le vector nous permettant d'afficher les features retournées par handleOverlay
      vectorSource: undefined,
      vectorLayer: undefined,
      // La vue de la carto
      view: undefined,
      // Le niveau de zoom de la carto
      zoom: null,
    };
  },
  computed: {
    // Renvoi dynamiquement la donnée à afficher
    urlLayer() {
      let result
      result =
        process.env.VUE_APP_MAPSERVER_URL +
        "data_tileset_" +
        this.dataToLoad + "_" + this.levelSelected +
        "@kermap/{z}/{x}/{-y}.png";
      return result;
    },
    urlLayer2() {
      return process.env.VUE_APP_MAPSERVER_URL + "data_tileset_" + this.dataToLoad2 + "_" + this.levelSelected + "@kermap/{z}/{x}/{-y}.png";
    }
  },
  mounted() {
    this.createMap();
    const container = document.getElementById('popup');
    this.overlayContent = {
      "year": "",
      "code1": "",
      "niv1": "",
      "code2": "",
      "niv2": "",
      "code3": "",
      "niv3": "",
      "code4": "",
      "niv4": "",
      "code5": "",
      "niv5": "",
      "area": "",
      "errorBool": false,
      "error": "Malheureusement, cette entité n'est pas référencée"
    }
    this.overlayContent2 = {
      "year": "",
      "code1": "",
      "niv1": "",
      "code2": "",
      "niv2": "",
      "code3": "",
      "niv3": "",
      "code4": "",
      "niv4": "",
      "code5": "",
      "niv5": "",
      "area": "",
      "errorBool": false,
      "error": "Malheureusement, cette entité n'est pas référencée"
    }
    this.overlay = new Overlay({
      element: container,
      autoPan: true,
      autoPanAnimation: {
        duration: 250
      }
    })
  },
  methods: {
    activateSwipe () {
      this.map.getLayers().insertAt(1, this.ourLayer2)
      this.swipeControl = new Swipe()
      this.swipeControl.addLayer(this.ourLayer, false)
      this.swipeControl.addLayer(this.ourLayer2, true)
      this.map.addControl(this.swipeControl)
      this.swipeIsActivated = true
    },
    closeTutoDialog() {
      this.tuto = false
      this.toggleInfos()
    },
    disableSwipe () {
      this.map.removeLayer(this.ourLayer2)
      this.map.removeControl(this.swipeControl)
      this.swipeIsActivated = false
    },
    // Fonction permettant d'initialiser la carte avec la basemap Orthophotographique (non utilisée actuellement)
    orthoBasemap() {
      var self = this;
      var parser = new WMTSCapabilities();
      axios
        .get(
          "https://www.datagrandest.fr/geoserver/gwc/service/wmts?REQUEST=getcapabilities"
        )
        .then(function (response) {
          var result = parser.read(response.data);
          var options = optionsFromCapabilities(result, {
            layer: "geograndest:GGE_ORTHO_RVB_ACTUELLE",
            matrixSet: "EPSG:3857",
          });
          self.basemap = new TileLayer({
            source: new WMTS(options),
          });
          self.createMapLabel();
        });
    },
    // Fonction permettant d'initialiser la carte avec la basemap OSM classique (utilisé)
    classicBasemap() {
      var self = this;
      var parser = new WMTSCapabilities();
      axios
        .get(
          "https://osm.datagrandest.fr/mapcache/wmts?service=WMTS&request=GetCapabilities"
        )
        .then(function (response) {
          var result = parser.read(response.data);
          var options = optionsFromCapabilities(result, {
            layer: "pure",
            matrixSet: "EPSG:3857",
          });
          self.basemap = new TileLayer({
            source: new WMTS(options),
          });
          self.createMapLabel();
        });
    },
    // Zoom sur la communes sélectionnée et affiche ses statistiques
    // La garde également en mémoire pour permettre à l'utilisateur de revenir rapidement dessus
    citySearched(city) {
      var data = {}
      var self = this;
      this.cityHistorized.id = city.id
      this.cityHistorized.niveau = city.niveau
      if (city.niveau === 'commune') {
        data = { insee_com: city.id };
        var data_stat = { code_admin: city.id };
        axios
          .post(process.env.VUE_APP_API_URL + "inseecommunesgeom", data)
          .then(function (result) {
            proj4.defs(
              "EPSG:2154",
              "+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
            );
            register(proj4);
            self.view.setCenter(
              transform(
                [result.data.coordinates[0], result.data.coordinates[1]],
                "EPSG:2154",
                "EPSG:3857"
              )
            );
            self.view.setZoom(13);
          });
        axios
          .post(
            process.env.VUE_APP_API_URL + "indicateurviewer/particularcommstat",
            data_stat
          )
          .then(function (result) {
            self.currentCityStat = result.data;
          });
        this.currentCity = city;
        this.citySelected = true;
        this.updateMapLayers("communes");
      } else if (city.niveau === "epci") {
        this.currentCity = city
        this.citySelected = true;
        this.updateMapLayers("epci");
        data = { 'id_epci': city.id }
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularepcistat', data)
        .then(function (result) {
          self.currentCityStat = result.data
        })
      } else if (city.niveau === "scot") {
        this.currentCity = city
        this.citySelected = true;
        this.updateMapLayers("scot");
        data = { 'id_scot': city.id }
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularscotstat', data)
        .then(function (result) {
          self.currentCityStat = result.data
        })
      } else if (city.niveau === "pnr") {
        this.currentCity = city
        this.citySelected = true;
        this.updateMapLayers("pnr");
        data = { 'id_pnr': city.id }
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particularpnrstat', data)
        .then(function (result) {
          self.currentCityStat = result.data
        })
      } else if (city.niveau === "departement") {
        this.currentCity = city
        this.citySelected = true;
        this.updateMapLayers("dpt");
        data = { 'code_dept': city.id }
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/particulardeptstat', data)
        .then(function (result) {
          self.currentCityStat = result.data
        })
      }
      data = { 'id': city.id }
      axios.post(process.env.VUE_APP_API_URL + "getpopulationdata", data)
      .then((result) => {
        this.cityPopulation = result.data
      })
    },
    // Permet le changement de date.
    // Pour le moment, seul 2019 et 2010 sont disponibles
    changeDate(val) {
      this.dataToLoad = val;
    },
    changeDate2(val) {
      this.dataToLoad2 = val;
    },
    // Créer la map
    createMap() {
      // Ici, nous initialisons la carte avec la basemap OSM
      // Nous pouvons modifier l'appel à la fonction ici pour initialiser la carte avec le flux orthophotographique
      this.classicBasemap();
    },
    // Initialise les labels à afficher (principalement les noms de communes)
    createMapLabel() {
      var self = this;
      var parser = new WMTSCapabilities();
      axios
        .get(
          "https://osm.datagrandest.fr/mapcache/wmts?service=WMTS&request=GetCapabilities"
        )
        .then(function (response) {
          var result = parser.read(response.data);
          var options = optionsFromCapabilities(result, {
            layer: "overlay",
            matrixSet: "EPSG:3857",
          });
          self.basemapLabel = new TileLayer({
            source: new WMTS(options),
          });
          self.initMapObject();
          if (self.$route.params.insee) {
            self.initMapObjectFromId(self.$route.params.insee)
          }
        });
    },
    deleteFeature () {
      this.overlay.setPosition(undefined);
    },
    disablePopupColor() {
      this.settingsIsOpen = false
    },
    disableSearchColor() {
      this.searchIsOpen = false
    },
    // Initialise les objets présents sur la map
    initMapObject() {
      var self = this;
      // Définie une échelle pour la carte
      var scalelineControl = new ScaleLine();
      var center = fromLonLat([6.87, 48.76]);
      // Défini le zoom de la carte
      self.zoom = 8.5;
      // Défini la vue de la carte
      self.view = new View({
        zoom: self.zoom,
        center: center,
        maxZoom: 18,
        minZoom: 8,
      });
      // On ajoute notre layer à la carte
      self.ourLayer = new TileLayer({
        source: new XYZ({
          url: self.urlLayer,
        }),
      });

      self.ourLayer2 = new TileLayer({
        source: new XYZ({
          url: self.urlLayer2,
        }),
      });

      // Nous définissons les layers des différentes limites administratives
      self.limitesCommunesLayer = new TileLayer({
        source: new XYZ({
          url:
            process.env.VUE_APP_MAPSERVER_URL +
            "limites_communes_tileset@kermap/{z}/{x}/{-y}.png",
        }),
      });

      self.limitesEpciLayer = new TileLayer({
        source: new XYZ({
          url:
            process.env.VUE_APP_MAPSERVER_URL +
            "limites_epci_tileset@kermap/{z}/{x}/{-y}.png",
        }),
      });

      self.limitesScotLayer = new TileLayer({
        source: new XYZ({
          url:
            process.env.VUE_APP_MAPSERVER_URL +
            "limites_scot_tileset@kermap/{z}/{x}/{-y}.png",
        }),
      });

      self.limitesPnrLayer = new TileLayer({
        source: new XYZ({
          url:
            process.env.VUE_APP_MAPSERVER_URL +
            "limites_pnr_tileset@kermap/{z}/{x}/{-y}.png",
        }),
      });

      self.limitesDepartementLayer = new TileLayer({
        source: new XYZ({
          url:
            process.env.VUE_APP_MAPSERVER_URL +
            "limites_departements_tileset@kermap/{z}/{x}/{-y}.png",
        }),
      });

      // Définissons le layer vectorielle visant à accueillir les features
      // retournée par la fonction handleOverlay()
      self.vectorSource = new VectorSource({
        format: new GeoJSON({dataProjection: 'EPSG:4328'})
      })
      self.vectorLayer = new VectorLayer({
        source: self.vectorSource,
        style: this.styleFunction
      })

      // Initialisation de la carte
      self.map = new Map({
        controls: defaultControls().extend([scalelineControl]),
        target: "explore-div",
        view: self.view,
        overlays: [self.overlay],
        layers: [
          self.basemap,
          self.ourLayer,
          self.limitesDepartementLayer,
          self.basemapLabel,
          self.vectorLayer,
        ],
      });
      // On ajoute un listener d'évènement sur le déplacement de la carte pour garder notre valeur de zoom à jour
      self.map.on("moveend", function () {
        self.zoom = self.map.getView().getZoom();
      });
    },
    initMapObjectFromId (insee) {
      var param = {
        insee_com: insee
      }
      var self = this
      axios.post(process.env.VUE_APP_API_URL + 'searchinsee', param)
      .then(function (response) {
        self.citySearched(response.data)
      })
    },
    // Affiche ou non la légende de la carte
    displayLegend() {
      // Permet de déployer (ou de cacher) le drawer affichant le composant de légende
      this.$refs.legendComponent.show = !this.$refs.legendComponent.show;
      this.searchIsOpen = false
      this.settingsIsOpen = false
      this.legendIsShown = !this.legendIsShown
      // Nous récupérons les éléments susceptible de passer sous le drawer de la légende
      const scale = document.getElementsByClassName("ol-scale-line");
      const zoom = document.getElementsByClassName("ol-zoom");
      // Si la légende est affichée
      if (this.$refs.legendComponent.show) {
        // Nous déplaçons l'échelle de quelques pixels sur la gauche
        scale.forEach(function (element) {
          element.style.left = "440px";
        });
        // Nous déplaçons les boutons de zoom de quelques pixels sur la gauche
        zoom.forEach(function (zoomElement) {
          zoomElement.style.left = "440px";
        });
        // Si la légende n'est pas affichée
      } else if (!this.$refs.legendComponent.show) {
        // Nous remettons l'échelle à sa place
        scale.forEach((element) => {
          element.style.left = "60px";
        });
        // Nous remettons les boutons de zoom à sa place
        zoom.forEach((zoomElement) => {
          zoomElement.style.left = "60px";
        });
      }
    },
    // Permet de déployer ou non le drawer contenant le composant permettant de gérer différents paramètres sur la carte
    // (Voir ci-dessus pour le détail)
    displayPopup() {
      this.$refs.legendComponent.show = false;
      this.$refs.popupComponent.show = !this.$refs.popupComponent.show;
      this.searchIsOpen = false
      this.legendIsShown = false
      this.settingsIsOpen = !this.settingsIsOpen
      const scale = document.getElementsByClassName("ol-scale-line");
      const zoom = document.getElementsByClassName("ol-zoom");
      if (this.$refs.legendComponent.show) {
        scale.forEach(function (element) {
          element.style.left = "440px";
        });
        zoom.forEach(function (zoomElement) {
          zoomElement.style.left = "440px";
        });
      } else if (!this.$refs.legendComponent.show) {
        scale.forEach((element) => {
          element.style.left = "60px";
        });
        zoom.forEach((zoomElement) => {
          zoomElement.style.left = "60px";
        });
      }
    },
    // Affiche ou non le popup d'avertissement sur l'impression
    displayPrintDialog() {
      this.printDialog = !this.printDialog;
    },
    // Affiche ou non le drawer contenant la recherche de communes (Voir ci-dessus pour le détail de la fonction)
    displaySearch() {
      this.$refs.legendComponent.show = false;
      this.$refs.searchComponent.show = !this.$refs.searchComponent.show;
      this.legendIsShown = false
      this.settingsIsOpen = false
      this.searchIsOpen = !this.searchIsOpen
      const scale = document.getElementsByClassName("ol-scale-line");
      const zoom = document.getElementsByClassName("ol-zoom");
      if (this.$refs.legendComponent.show) {
        scale.forEach(function (element) {
          element.style.left = "440px";
        });
        zoom.forEach(function (zoomElement) {
          zoomElement.style.left = "440px";
        });
      } else if (!this.$refs.legendComponent.show) {
        scale.forEach((element) => {
          element.style.left = "60px";
        });
        zoom.forEach((zoomElement) => {
          zoomElement.style.left = "60px";
        });
      }
    },
    // Accède ou non à la nomenclature niveau 5
    goToLastLevel() {
      this.level_5 = !this.level_5;
    },
    // Cette fonction gère l'affichage et la position de l'overlay au click sur la carte
    // (si la fonctionnalité est activée)
    handleOverlay(event) {
      const coordinates = event.coordinate
      const highlightStyle = new Style({
        fill: new Fill({
          color: 'rgba(255,255,255,0.7)',
        }),
        stroke: new Stroke({
          color: '#3399CC',
          width: 5,
        }),
      });
      var data
      if (this.swipeIsActivated) {
        data = { "coord": toLonLat(coordinates), "date": this.dataToLoad2 }
        axios.post(process.env.VUE_APP_API_URL + 'ocs/get_feature', data)
        .then((response) => {
          this.vectorSource.clear()
          if (response.data === 'error') {
            this.overlayContent2.errorBool = true
            this.overlayContent2.area = ""
            this.overlayContent2.year = ""
          } else {
            this.overlayContent2.errorBool = false
            this.overlayContent2.year = response.data.millesime
            this.overlayContent2.code1 = response.data.cod_n1
            this.overlayContent2.niv1 = response.data.lib_n1
            this.overlayContent2.code2 = response.data.cod_n2
            this.overlayContent2.niv2 = response.data.lib_n2
            this.overlayContent2.code3 = response.data.cod_n3
            this.overlayContent2.niv3 = response.data.lib_n3
            this.overlayContent2.code4 = response.data.cod_n4
            this.overlayContent2.niv4 = response.data.lib_n4
            this.overlayContent2.code5 = response.data.cod_n5
            this.overlayContent2.niv5 = response.data.lib_n5
            this.overlayContent2.area = response.data.surf_ha
            this.vectorSource.addFeature(new GeoJSON().readFeature(response.data.shape))
            this.vectorSource.getFeatures().forEach((feature) => {
              feature.setStyle(highlightStyle)
            })
          }
        })
      }
      data = { "coord": toLonLat(coordinates), "date": this.dataToLoad }
      axios.post(process.env.VUE_APP_API_URL + 'ocs/get_feature', data)
      .then((response) => {
        this.vectorSource.clear()
        if (response.data === 'error') {
          this.overlay.setPosition(coordinates);
          this.overlayContent.errorBool = true
          this.overlayContent.area = ""
          this.overlayContent.year = ""
        } else {
          this.overlayContent.errorBool = false
          this.overlayContent.year = response.data.millesime
          this.overlayContent.code1 = response.data.cod_n1
          this.overlayContent.niv1 = response.data.lib_n1
          this.overlayContent.code2 = response.data.cod_n2
          this.overlayContent.niv2 = response.data.lib_n2
          this.overlayContent.code3 = response.data.cod_n3
          this.overlayContent.niv3 = response.data.lib_n3
          this.overlayContent.code4 = response.data.cod_n4
          this.overlayContent.niv4 = response.data.lib_n4
          this.overlayContent.code5 = response.data.cod_n5
          this.overlayContent.niv5 = response.data.lib_n5
          this.overlayContent.area = response.data.surf_ha
          this.vectorSource.addFeature(new GeoJSON().readFeature(response.data.shape))
          this.vectorSource.getFeatures().forEach((feature) => {
            feature.setStyle(highlightStyle)
          })
          this.overlay.setPosition(coordinates);
        }
      })
    },
    // Appelé à la réception de l'évènement d'impression
    print() {
      this.displayPrintDialog();
    },
    // Cache le popup d'impression et ouvre la fenêtre d'impression du navigateur
    printWindow() {
      this.printDialog = false;
      setTimeout(function () {
        window.print();
      }, 500);
    },
    // Change les statistiques à afficher
    newStat(value) {
      this.currentCityStat = value;
    },
    reloadRegion () {
      this.citySelected = false
      var center = fromLonLat([6.87, 48.76]);
      this.map.getView().setZoom(8.5)
      this.map.getView().setCenter(center)
      this.cityHistorized = {
        id: null,
        niveau: null
      }
    },
    // Permet à l'utilisateur de revenir au territoire observé
    rezoom() {
      var self = this
      var data
      if (this.cityHistorized.niveau === null) {
        console.error("Aucune entité n'a été recherchée précédemment")
      } else if (this.cityHistorized.niveau === 'commune') {
        data = { insee_com: this.cityHistorized.id };
        axios
          .post(process.env.VUE_APP_API_URL + "inseecommunesgeom", data)
          .then(function (result) {
            proj4.defs(
              "EPSG:2154",
              "+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
            );
            register(proj4);
            self.view.setCenter(
              transform(
                [result.data.coordinates[0], result.data.coordinates[1]],
                "EPSG:2154",
                "EPSG:3857"
              )
            );
            self.view.setZoom(13);
          });
      } else if (this.cityHistorized.niveau === 'epci') {
        data = { 'id_epci': this.cityHistorized.id }
        axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/epciextent', data)
        .then(function (response) {
          // Filtre ce que nous souhaitons récupéré de la requête
          var regExp = /\(([^)]+)\)/;
          var result = regExp.exec(response.data);
          var stringRes = result[1].split(/[ ,]+/).join(',')
          var stringExtent = '[' + stringRes + ']'
          var value = JSON.parse(stringExtent)
          // Transforme ce que nous avons dans la bonne projection pour l'adapter à notre carte
          proj4.defs("EPSG:2154","+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
          register(proj4)
          // Adapte la carte à la vue passée
          self.map.getView().fit(transformExtent(value, 'EPSG:2154', 'EPSG:3857'))
        })
      } else if (this.cityHistorized.niveau === 'scot') {
        data = { 'id_scot': this.cityHistorized.id }
        axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/scotextent', data)
        .then(function (response) {
          var regExp = /\(([^)]+)\)/;
          var result = regExp.exec(response.data);
          var stringRes = result[1].split(/[ ,]+/).join(',')
          var stringExtent = '[' + stringRes + ']'
          var value = JSON.parse(stringExtent)
          proj4.defs("EPSG:2154","+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
          register(proj4)
          self.map.getView().fit(transformExtent(value, 'EPSG:2154', 'EPSG:3857'))
        })
      } else if (this.cityHistorized.niveau === 'pnr') {
        data = { 'id_pnr': this.cityHistorized.id }
        axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/pnrextent', data)
        .then(function (response) {
          var regExp = /\(([^)]+)\)/;
          var result = regExp.exec(response.data);
          var stringRes = result[1].split(/[ ,]+/).join(',')
          var stringExtent = '[' + stringRes + ']'
          var value = JSON.parse(stringExtent)
          proj4.defs("EPSG:2154","+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
          register(proj4)
          self.map.getView().fit(transformExtent(value, 'EPSG:2154', 'EPSG:3857'))
        })
      } else if (this.cityHistorized.niveau === 'departement') {
        data = { 'code_dept': this.cityHistorized.id }
        axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/departementextent', data)
        .then(function (response) {
          var regExp = /\(([^)]+)\)/;
          var result = regExp.exec(response.data);
          var stringRes = result[1].split(/[ ,]+/).join(',')
          var stringExtent = '[' + stringRes + ']'
          var value = JSON.parse(stringExtent)
          proj4.defs("EPSG:2154","+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
          register(proj4)
          self.map.getView().fit(transformExtent(value, 'EPSG:2154', 'EPSG:3857'))
        })
      }
    },
    // Change le fond de carte
    setBasemap(value) {
      if (value === "ortho") {
        this.updateOrtho2019();
      } else if (value === "classic") {
        this.updateOsm();
      } else if (value === "ortho-2010") {
        this.updateOrtho2010()
      } else if (value === "ign") {
        this.updateIgn();
      }
    },
    // Change le niveau de nomenclature à afficher
    setLevel(value) {
      this.levelSelected = value
    },
    // Change la valeur d'opacité du layer
    setOpacity(value) {
      this.ourLayer.setOpacity(value / 100);
    },
    setOpacity2(value) {
      this.ourLayer2.setOpacity(value / 100);
    },
    styleFunction (feature) {
      const styles = {
        'Polygon': new Style({
          stroke: new Stroke({
            color: 'blue',
            lineDash: [4],
            width: 3,
          }),
          fill: new Fill({
            color: 'rgba(0, 0, 255, 0.1)',
          }),
        }),
        'MultiPolygon': new Style({
          stroke: new Stroke({
            color: 'yellow',
            width: 1,
          }),
          fill: new Fill({
            color: 'rgba(255, 255, 0, 0.1)',
          }),
        }),
      }
      return styles[feature.getGeometry().getType()];
    },
    // Remplace le fond de carte actuel pour le fond de carte orthophotographique 2010
    updateOrtho2010() {
      var self = this;
      var parser = new WMTSCapabilities();
      axios
        .get(
          "https://www.datagrandest.fr/geoserver/gwc/service/wmts?REQUEST=getcapabilities"
        )
        .then(function (response) {
          var result = parser.read(response.data);
          var options = optionsFromCapabilities(result, {
            layer: "geograndest:ORTHO_2007_2010_GRAND_EST",
            matrixSet: "EPSG:3857",
          });
          self.basemap.setSource(new WMTS(options));
          self.map.changed();
        });
    },
    // Remplace le fond de carte actuel pour le fond de carte orthophotographique 2019
    updateOrtho2019() {
      var self = this;
      var parser = new WMTSCapabilities();
      axios
        .get(
          "https://www.datagrandest.fr/geoserver/gwc/service/wmts?REQUEST=getcapabilities"
        )
        .then(function (response) {
          var result = parser.read(response.data);
          var options = optionsFromCapabilities(result, {
            layer: "geograndest:GGE_ORTHO_RVB_ACTUELLE",
            matrixSet: "EPSG:3857",
          });
          self.basemap.setSource(new WMTS(options));
          self.map.changed();
        });
    },
    // Remplace le fond de carte actuel pour le fond de carte OSM
    updateOsm() {
      var self = this;
      var parser = new WMTSCapabilities();
      axios
        .get(
          "https://osm.datagrandest.fr/cache/wmts?service=WMTS&request=GetCapabilities"
        )
        .then(function (response) {
          var result = parser.read(response.data);
          var options = optionsFromCapabilities(result, {
            layer: "pure",
            matrixSet: "EPSG:3857",
          });
          self.basemap.setSource(new WMTS(options));
          self.map.changed();
        });
    },
    // Change le type de donnée à afficher
    updateCityType(value) {
      this.cityType = value;
    },
    // Change les délimitations administratives à afficher
    updateMapLayers(value) {
      if (
        value === "communes" &&
        !this.map.getLayers().array_.includes(this.limitesCommunesLayer)
      ) {
        this.map.removeLayer(this.limitesEpciLayer);
        this.map.removeLayer(this.limitesPnrLayer);
        this.map.removeLayer(this.limitesScotLayer);
        this.map.removeLayer(this.limitesDepartementLayer);
        if (this.swipeIsActivated) {
          this.map.getLayers().insertAt(3, this.limitesCommunesLayer);
        } else {
          this.map.getLayers().insertAt(2, this.limitesCommunesLayer);
        }
      } else if (
        value === "epci" &&
        !this.map.getLayers().array_.includes(this.limitesEpciLayer)
      ) {
        this.map.removeLayer(this.limitesCommunesLayer);
        this.map.removeLayer(this.limitesPnrLayer);
        this.map.removeLayer(this.limitesScotLayer);
        this.map.removeLayer(this.limitesDepartementLayer);
        if (this.swipeIsActivated) {
          this.map.getLayers().insertAt(3, this.limitesEpciLayer);
        } else {
          this.map.getLayers().insertAt(2, this.limitesEpciLayer);
        }
      } else if (
        value === "scot" &&
        !this.map.getLayers().array_.includes(this.limitesScotLayer)
      ) {
        this.map.removeLayer(this.limitesEpciLayer);
        this.map.removeLayer(this.limitesPnrLayer);
        this.map.removeLayer(this.limitesCommunesLayer);
        this.map.removeLayer(this.limitesDepartementLayer);
        if (this.swipeIsActivated) {
          this.map.getLayers().insertAt(3, this.limitesScotLayer);
        } else {
          this.map.getLayers().insertAt(2, this.limitesScotLayer);
        }
      } else if (
        value === "pnr" &&
        !this.map.getLayers().array_.includes(this.limitesPnrLayer)
      ) {
        this.map.removeLayer(this.limitesEpciLayer);
        this.map.removeLayer(this.limitesCommunesLayer);
        this.map.removeLayer(this.limitesScotLayer);
        this.map.removeLayer(this.limitesDepartementLayer);
        if (this.swipeIsActivated) {
          this.map.getLayers().insertAt(3, this.limitesPnrLayer);
        } else {
          this.map.getLayers().insertAt(2, this.limitesPnrLayer);
        }
      } else if (
        value === "dpt" &&
        !this.map.getLayers().array_.includes(this.limitesDepartementLayer)
      ) {
        this.map.removeLayer(this.limitesEpciLayer);
        this.map.removeLayer(this.limitesPnrLayer);
        this.map.removeLayer(this.limitesScotLayer);
        this.map.removeLayer(this.limitesCommunesLayer);
        if (this.swipeIsActivated) {
          this.map.getLayers().insertAt(3, this.limitesDepartementLayer);
        } else {
          this.map.getLayers().insertAt(2, this.limitesDepartementLayer);
        }
      } else if (value === "classic") {
        this.map.removeLayer(this.limitesEpciLayer);
        this.map.removeLayer(this.limitesPnrLayer);
        this.map.removeLayer(this.limitesScotLayer);
        this.map.removeLayer(this.limitesCommunesLayer);
        this.map.removeLayer(this.limitesDepartementLayer);
      }
    },
    checkToggleInfos() {
      if (this.infos) {
        this.toggleInfos()
      } else {
        if (localStorage.getItem("DGE-remember-entity-request") === "true") {
          this.toggleInfos()
        } else {
          this.tuto = true
        }
      }
    },
    // Permet d'activer ou non le click sur la map pour afficher des informations de features
    toggleInfos() {
      this.searchIsOpen = false
      this.settingsIsOpen = false
      this.infos = !this.infos
      if (this.infos) {
        this.map.on("click", this.handleOverlay)
      } else {
        this.map.un("click", this.handleOverlay)
        this.vectorSource.clear()
        this.overlay.setPosition(undefined);
      }
    },
    // Affiche ou non le drawer de statistiques (à droite)
    toggledDrawer() {
      this.drawer = !this.drawer;
    },
  },
  watch: {
    // Si le niveau 5 est sélectionnée, nous retreignons le niveau de zoom possible
    level_5(val) {
      if (val) {
        this.view.setZoom(18);
        this.view.setMinZoom(17);
      } else {
        this.view.setMinZoom(0);
      }
    },
    // Si l'url de notre donnée change, nous mettons à jour le layer à afficher sur la carte
    urlLayer(newVal) {
      var source = new XYZ({
        url: newVal,
      });
      this.ourLayer.setSource(source);
    },
    urlLayer2(newVal) {
      var source = new XYZ({
        url: newVal,
      });
      this.ourLayer2.setSource(source);
    },
  },
};
</script>

<style>
.ol-scale-line {
  left: 60px;
}

.ol-zoom {
  left: 60px;
}

.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0,0,0,0.2);
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 280px;
  opacity: 0.85
}
.ol-popup:after, .ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  position: absolute;
  top: -12px;
  right: -12px;
}

.ol-popup-swipe {
  min-width: 400px;
}
</style>