<!-- Ce composant gère la recherche de communes -->
<template>
  <v-navigation-drawer disable-resize-watcher class="d-print-none" v-model="show" absolute width="380px" style="z-index: 900; left: 56px; box-shadow: inherit; border-right: 1px thin #e0e0e0" temporary hide-overlay>
    <div style="width: 321px; margin-top: 35px; margin-left: 30px">
      <v-autocomplete
        rounded
        :outlined="outline"
        :solo="!outline"
        label="Rechercher"
        v-model="citySelected"
        :items="citiesSuggested"
        item-text="nom"
        item-value="id"
        :search-input.sync="search"
        no-filter
        hide-no-data
        return-object
      >
        <template slot="item" slot-scope="citiesSuggested">
          {{ citiesSuggested.item.nom }} <span class="ml-1 mt-1" style="color: grey; font-size: 12px"> -  {{ citiesSuggested.item.niveau }}</span>
        </template>
      </v-autocomplete>
    </div>
    <v-list>
      <v-list-item
        v-for="city in Cities"
        :key="city.id"
      >
        <City :name="city.name" :id="city.id" :insee="city.insee" :surface="city.surface" :population="city.population"></City>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import Cities from "@/assets/cities.json"
import City from "@/components/Home/City"
// Import des dépendances
import axios from 'axios'

export default {
  name: 'search',
  components: {
    City
  },
  props: {
    // Props permettant de définir le style
    outline: {
      type: Boolean,
      required: false,
      default: true
    },
    // La carte
    map: {
      type: Object
    }
  },
  data () {
    return {
      Cities: Cities,
      // Villes que l'autocomplete doit suggérer
      citiesSuggested: [],
      //  Ville choisie par l'utilisateur
      citySelected: null,
      // Contenu de la recherche
      search: '',
      // Affiche ou non le drawer de recherche
      show: false
    }
  },
  methods: {
    // Supprime les villes suggérées par l'autocomplete
    clearEntries() {
      this.citiesSuggested = []
    },
    // Ajoute un timer pour éviter de balancer un nombre important de requêtes
    fetchEntriesDebounced (city) {
      var self = this
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        self.fetchEntries(city)
      }, 1000)
    },
    // Interroge la base de donnée en fonction de ce qu'à taper l'utilisateur
    fetchEntries (city) {
      // Si la ville n'est pas null
      if (city !== null) {
        // On réinitialise les villes suggérées par l'autocomplete
        this.citiesSuggested = []
        // Sauvegarde le contexte de l'application
        var self = this
        // Affiche une animation de chargement
        this.isLoading = true
        // Création de la data
        var data = { 'value': this.slugify(city).toUpperCase() }
        // Requête la base de donnée
        axios.post(process.env.VUE_APP_API_URL + 'search2', data)
        .then(function (results) {
          results.data.forEach(function (result) {
            self.citiesSuggested.push(result)
          })
        })
      }
    },
    // Format de retour de la recherche (nom_communes (numéro_departement))
    format (item) {
      return item.nom + " - " + item.niveau
    },
    // Supprime les accents
    slugify (str) {
      var map = {
        'a' : 'á|à|ã|â|À|Á|Ã|Â',
        'e' : 'é|è|ê|É|È|Ê',
        'i' : 'í|ì|î|Í|Ì|Î',
        'o' : 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
        'u' : 'ú|ù|û|ü|Ú|Ù|Û|Ü',
        'c' : 'ç|Ç',
        'n' : 'ñ|Ñ'
      };
        
      for (var pattern in map) {
        str = str.replace(new RegExp(map[pattern], 'g'), pattern);
      }

      return str;
    }
  },
  watch: {
    // Dès qu'une ville est sélectionnée, nous balançons un évènement pour signaler le changement sur la carte
    citySelected (val) {
      this.$emit('citySelected', val)
    },
    // Dès que le contenu de la recherche change, balance une requête
    // FONCTION IMPORTANTE POUR CE COMPOSANT
    search (city) {
      // Empêche le déclenchement de la requête si le contenu est vide
      if (!city) {
        return
      }

      // Efface les entrées suggérées par l'autocomplete
      this.clearEntries()
      // Déclenche l'animation de chargement
      this.isLoading = true
      // Déclenche la requête
      this.fetchEntriesDebounced(city)
    },
    show(newVal) {
      if (newVal === false) {
        this.$emit('closeDial')
      }
    }
  }
}
</script>