<!-- Ce composant gère l'affichage de la légende en fonction du niveau de zoom de la carte -->
<template>
  <v-navigation-drawer stateless v-model="show" absolute width="380px" style="overflow: auto; z-index: 900; left: 56px; box-shadow: inherit; border-right: 1px thin #e0e0e0; padding: 2px" hide-overlay>
    <v-container>
      <!-- Bouton permettant de fermer le drawer de légende -->
      <v-btn @click="hideDrawer" icon large style="position: absolute; right: .5em">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <!-- Titre -->
      <div style="margin-bottom: 5%">Légende : 
      </div>
      <!-- Charge la légende correspondante au niveau de zoom (et/ou niveau de nomenclature) -->
      <v-img
        :src="require('@/assets/legends/' + this.imgSrc)"
        :lazy-src="require('@/assets/legends/' + this.imgSrc)"
      ></v-img>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "Legend",
  props: {
    zoom: {
      type: Number,
      required: false,
    },
    levelSelected: {
      type: Number
    }
  },
  data() {
    return {
      level_5: false,
      page: 1,
      show: false
    };
  },
  computed: {
    // Renvoit dynamiquement l'url source de l'image de légende en fonction du niveau de nomenclature
    imgSrc() {
      let ret
      ret = "niveau" + this.levelSelected + ".png";
      /* if (!this.level_5) {
        ret = "niveau1.png";
        if (this.zoom > 17.4) ret = "niveau4.png";
        else if (this.zoom > 14.4) ret = "niveau3.png";
        else if (this.zoom > 12.4) ret = "niveau2.png";
      } else {
        ret = "niveau5.png"
      } */
      return ret;
    }
  },
  methods: {
    // Cache la légende
    hideDrawer () {
      this.$emit('hideLegend')
    },
    // Active ou non la nomenclature de niveau 5
    particularLevel () {
      this.level_5 = !this.level_5
    }
  },
  watch: {
    // Emet un évènement pour que la carte puisse suivre la nomenclature
    level_5 () {
      this.$emit('showLastLevel')
    }
  }
};
</script>

<style >
.v-expansion-panel-content__wrap {
  padding: 4px;
}
</style>