<template>
  <v-card max-width="374">
    <v-container>
      <v-img contains height="250" :src="require('@/assets/images/tuto/entity.gif')" :lazy-src="require('@/assets/images/tuto/entity.png')"></v-img>
      <v-card-title><b>Outil Info-bulle</b></v-card-title>
      <v-card-text>
        <div>
          <p>Il semblerait que ce soit la première fois que vous activez cet outil.</p>
          <p>
            Cliquez sur une entité de la carte pour révéler différentes informations comme : 
            <ul>
              <li>Son millésime</li>
              <li>Sa nomenclature <span style="font-size: 12px; color: grey">(en fonction du niveau observé)</span></li>
              <li>Sa surface</li>
            </ul>
          </p>
          <p>L'outil est désactivable en cliquant sur le bouton ornée de l'icône : <v-icon color="#0075ce">mdi-map-marker-question</v-icon></p>
        </div>
        <v-row dense justify="center" align="center">
          <v-col>
            <v-checkbox v-model="remember" label="Ne plus afficher"></v-checkbox>
          </v-col>
          <v-col>
            <v-btn
              tile
              color="#4473c5"
              dark
              small
              @click="closeDial"
            >
              <v-icon left>
                mdi-check
              </v-icon>
              Compris
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'TutoEntity',
  data () {
    return {
      remember: false
    }
  },
  methods: {
    closeDial() {
      if (this.remember) {
        localStorage.setItem('DGE-remember-entity-request', "true");
      }
      this.$emit('closeDial')
    }
  }
}
</script>
