<!-- Ce composant permet à l'utilisateur de sélectionner l'affichage d'une délimitation administrative -->
<template>
  <v-card flat width="450px">
    <v-list style="padding: 0">
      <!-- Classique -->
      <v-list-item link @click="setMapStyle('classic')">
        <v-list-item-content style="padding: 0; padding-top: 3px">
          <v-row>
            <v-col cols="5">
              <v-img
                height="92px"
                width="92px"
                :src="require('@/assets/images/numbers_logo/puce_region.png')"
                :lazy-src="
                  require('@/assets/images/numbers_logo/puce_region.png')
                "
                contain
              />
            </v-col>
            <v-col cols="7">
              <p style="font-weight: bold; text-align: left">Classique</p>
              <p style="text-align: left; font-size: small">
                Désactive les contours
              </p>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>

      <!-- Communes -->
      <v-list-item link @click="setMapStyle('communes')">
        <v-list-item-content style="padding: 0; padding-top: 3px">
          <v-row>
            <v-col cols="5">
              <v-img
                height="92px"
                width="92px"
                :src="require('@/assets/images/numbers_logo/puce_comm.png')"
                :lazy-src="
                  require('@/assets/images/numbers_logo/puce_comm.png')
                "
                contain
              />
            </v-col>
            <v-col cols="7">
              <p style="font-weight: bold; text-align: left">Communes</p>
              <p style="text-align: left; font-size: small">
                Affiche les contours des communes
              </p>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>

      <!-- EPCI -->
      <v-list-item link @click="setMapStyle('epci')">
        <v-list-item-content style="padding: 0">
          <v-row>
            <v-col cols="5">
              <v-img
                height="92px"
                width="92px"
                :src="require('@/assets/images/numbers_logo/puce_epci.png')"
                :lazy-src="
                  require('@/assets/images/numbers_logo/puce_epci.png')
                "
                contain
              />
            </v-col>
            <v-col cols="7">
              <p style="font-weight: bold; text-align: left">EPCI</p>
              <p style="text-align: left; font-size: small">
                Affiche les contours des EPCI
              </p>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>

      <!-- PNR -->
      <v-list-item link @click="setMapStyle('pnr')">
        <v-list-item-content style="padding: 0">
          <v-row>
            <v-col cols="5">
              <v-img
                height="92px"
                width="92px"
                :src="require('@/assets/images/numbers_logo/puce_pnr.png')"
                :lazy-src="require('@/assets/images/numbers_logo/puce_pnr.png')"
                contain
              />
            </v-col>
            <v-col cols="7">
              <p style="font-weight: bold; text-align: left">PNR</p>
              <p style="text-align: left; font-size: small">
                Affiche les contours des PNR
              </p>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>

      <!-- SCOT -->
      <v-list-item link @click="setMapStyle('scot')">
        <v-list-item-content style="padding: 0">
          <v-row>
            <v-col cols="5">
              <v-img
                height="92px"
                width="92px"
                :src="require('@/assets/images/numbers_logo/puce_scot.png')"
                :lazy-src="
                  require('@/assets/images/numbers_logo/puce_scot.png')
                "
                contain
              />
            </v-col>
            <v-col cols="7">
              <p style="font-weight: bold; text-align: left">SCOT</p>
              <p style="text-align: left; font-size: small">
                Affiche les contours des SCOT
              </p>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>

      <!-- Département -->
      <v-list-item link @click="setMapStyle('dpt')">
        <v-list-item-content style="padding: 0">
          <v-row>
            <v-col cols="5">
              <v-img
                height="92px"
                width="92px"
                :src="require('@/assets/images/numbers_logo/puce_dept.png')"
                :lazy-src="
                  require('@/assets/images/numbers_logo/puce_dept.png')
                "
                contain
              />
            </v-col>
            <v-col cols="7">
              <p style="font-weight: bold; text-align: left">Départements</p>
              <p style="text-align: left; font-size: small">
                Affiche les contours des départements
              </p>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: "DelimAdmin",
  data() {
    return {
      show: false,
    };
  },
  methods: {
    // Emet un évènement changeant les délimitations administratives affichées
    setMapStyle(value) {
      this.$emit("changeMapStyle", value);
    },
  },
};
</script>