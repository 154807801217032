import { render, staticRenderFns } from "./Details.vue?vue&type=template&id=78cc2c2b&scoped=true&"
import script from "./Details.vue?vue&type=script&lang=js&"
export * from "./Details.vue?vue&type=script&lang=js&"
import style0 from "./Details.vue?vue&type=style&index=0&id=78cc2c2b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78cc2c2b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCol,VIcon,VNavigationDrawer,VRow,VSelect,VSubheader,VTooltip})
