<!-- Ce composant vise à gérer différents paramètres de la carte -->
<template>
  <v-navigation-drawer disable-resize-watcher class="d-print-none" v-model="show" absolute width="380px" style="z-index: 900; left: 56px; box-shadow: inherit; border-right: 1px thin #e0e0e0" temporary hide-overlay>
    <v-container>
      <!-- <v-img class="settings-img" :src="require('@/assets/images/explore_logo/map-settings.png')" max-width="38px"></v-img> -->
      <v-icon class="settings-img" large color="#4473c5">mdi-cogs</v-icon>
      <h2>Paramètres de la carte</h2>
      <div class="mt-8">

        <!-- Section de configuration de la donnée -->
        <v-row align="center" justify="center">
          <v-col cols="9">
            <p class="mb-0">Affichage des données</p>
          </v-col>
          <v-col>
            <v-btn icon @click="toggleData()">
              <v-icon v-if="!data">mdi-menu-down</v-icon>
              <v-icon v-if="data">mdi-menu-up</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <DataOptn v-if="data" :swipeIsActivated="swipe" @updateOpacity="updateOpacity($event)" @updateOpacity2="updateOpacity2($event)" @changeLevel="changeLevel($event)"/>

        <!-- Section pour changer de fond de carte -->
        <v-row align="center" justify="center">
          <v-col cols="9">
            <p class="mb-0">Fond de carte</p>
          </v-col>
          <v-col>
            <v-btn icon @click="toggleBasemap()">
              <v-icon v-if="!basemap">mdi-menu-down</v-icon>
              <v-icon v-if="basemap">mdi-menu-up</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <BasemapSelector v-if="basemap" @changeBasemap="setBasemap($event)"/>

        <!-- Section pour changer la délimitation administrative affichée -->
        <v-row align="center" justify="center">
          <v-col cols="9">
            <p class="mb-0">Délimitations administratives</p>
          </v-col>
          <v-col>
            <v-btn icon @click="toggleAdmin()">
              <v-icon v-if="!admin">mdi-menu-down</v-icon>
              <v-icon v-if="admin">mdi-menu-up</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <DelimAdmin v-if="admin" @changeMapStyle="setMapStyle($event)"/>

      </div>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
// Outil pour changer la basemap
import BasemapSelector from "@/components/Explore/Parameters/BasemapSelector"
// Outil pour changer les paramètres de la donnée
import DataOptn from "@/components/Explore/Parameters/DataOptn"
// Outil pour modifier les délimitations administratives
import DelimAdmin from "@/components/Explore/Parameters/DelimAdmin"

export default {
  name: 'Popup',
  props: {
    swipe: {
      type: Boolean
    }
  },
  data () {
    return {
      // Affiche ou non l'outil de modification des délimitations administratives
      admin: false,
      // Affiche ou non l'outil de modification des basemaps
      basemap: false,
      // Affiche ou non l'outil de modifications des paramètres de la donnée
      data: true,
      show: false
    }
  },
  components: {
    BasemapSelector,
    DataOptn,
    DelimAdmin,
  },
  methods: {
    changeLevel (value) {
      this.$emit('changeLevel', value)
    },
    // Emet un évènement sur le changement de basemap
    setBasemap (value) {
      this.$emit('changeBasemap', value)
    },
    // Emet un évènement sur le changement de délimitation administrative
    setMapStyle (value) {
      this.$emit('changeMapStyle', value)
    },
    // Affiche ou non l'outil de modification des délimitations administratives
    toggleAdmin () {
      this.admin = !this.admin
    },
    // Affiche ou non l'outil de modification des basemaps
    toggleBasemap () {
      this.basemap = !this.basemap
    },
    // Affiche ou non l'outil de modifications des paramètres de la donnée
    toggleData () {
      this.data = !this.data
    },
    // Modifie l'opacité de la couche de donnée
    updateOpacity (value) {
      this.$emit('updateOpacity', value)
    },
    updateOpacity2 (value) {
      this.$emit('updateOpacity2', value)
    }
  },
  watch: {
    show(newVal) {
      if (newVal === false) {
        this.$emit('closeDial')
      }
    }
  }
}
</script>

<style scoped>
.flaticon-attribution {
  position: absolute;
  bottom: 0;
  background-color: white
}

.settings-img {
  float: right
}
</style>