<!-- Ce composant permet la modification des paramètres de la données -->
<template>
  <v-container class="mb-0 pb-0">
    <!-- Première option de configuration : l'opacité de la couche -->
    <div>
      <ul>
        <li>Opacité <span v-if="swipeIsActivated">(carte 1)</span></li>
      </ul>
      <!-- Slider permettant la sélection d'une valeur d'opacité allant de 0 à 100 -->
      <v-slider
        v-model="slider"
        :thumb-label="false"
      ></v-slider>
      <!-- Affichage de la valeur sélectionnée -->
      <v-row dense justify="center">
        <p class="slider-value"><b>{{ slider }} %</b></p>
      </v-row>
    </div>

    <div v-if="swipeIsActivated">
      <ul>
        <li>Opacité <span v-if="swipeIsActivated">(carte 2)</span></li>
      </ul>
      <!-- Slider permettant la sélection d'une valeur d'opacité allant de 0 à 100 -->
      <v-slider
        v-model="slider2"
        :thumb-label="false"
      ></v-slider>
      <!-- Affichage de la valeur sélectionnée -->
      <v-row dense justify="center">
        <p class="slider-value"><b>{{ slider2 }} %</b></p>
      </v-row>
    </div>

    <!-- Deuxieme option de configuration : sélection du niveau de nomenclature -->
    <div>
      <ul>
        <li>Niveau de nomenclature</li>
      </ul>
      <v-radio-group v-model="level">
        <v-radio
          class="mb-0"
          :value="1"
        >
          <template v-slot:label>
            <div style="color: black">Niveau 1</div>
          </template>
        </v-radio>
        <v-row dense class="mb-2">
          <v-col dense>
            <p class="ma-0 pa-0" style="font-size: 14px">5 postes de description de l'occupation du sol</p>
          </v-col>
          <v-col dense cols="2">
            <v-btn icon @click="displayPopupLegend(1)">
              <v-icon>mdi-information-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-radio
          class="mb-0"
          :value="2"
        >
          <template v-slot:label>
            <div style="color: black">Niveau 2</div>
          </template>
        </v-radio>
        <v-row dense class="mb-2">
          <v-col dense>
            <p class="ma-0 pa-0" style="font-size: 14px">15 postes de description de l'occupation du sol</p>
          </v-col>
          <v-col dense cols="2">
            <v-btn icon @click="displayPopupLegend(2)">
              <v-icon>mdi-information-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-radio
          class="mb-0"
          :value="3"
        >
          <template v-slot:label>
            <div style="color: black">Niveau 3</div>
          </template>
        </v-radio>
        <v-row dense class="mb-2">
          <v-col dense>
            <p class="ma-0 pa-0" style="font-size: 14px">38 postes de description de l'occupation du sol</p>
          </v-col>
          <v-col dense cols="2">
            <v-btn icon @click="displayPopupLegend(3)">
              <v-icon>mdi-information-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-radio
          class="mb-0"
          :value="4"
        >
          <template v-slot:label>
            <div style="color: black">Niveau 4</div>
          </template>
        </v-radio>
        <v-row dense class="mb-2">
          <v-col dense>
            <p class="ma-0 pa-0" style="font-size: 14px">53 postes de description de l'occupation du sol</p>
          </v-col>
          <v-col dense cols="2">
            <v-btn icon @click="displayPopupLegend(4)">
              <v-icon>mdi-information-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-radio
          label='Niveau 5'
          class="mb-0"
          :value="5"
        >
          <template v-slot:label>
            <div style="color: black">Niveau 5</div>
          </template>
        </v-radio>
        <v-row dense class="mb-2">
          <v-col dense>
            <p class="ma-0 pa-0" style="font-size: 14px">3 postes de description de l'occupation du sol - uniquement sur les territoires artificialisés</p>
          </v-col>
          <v-col dense cols="2">
            <v-btn icon @click="displayPopupLegend(5)">
              <v-icon>mdi-information-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-radio-group>
    </div>

    <v-dialog v-model="dialog" persistent max-width="35vw">
      <v-card class="dialog-card">
        <v-btn style="float: right" icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title>
          Légende
        </v-card-title>
        <v-img
          class="ma-1"
          :src="require('@/assets/legends/niveau' + levelPopup + '.png')"
          :lazy-src="require('@/assets/legends/niveau' + levelPopup + '.png')"
          max-width="25vw"
        ></v-img>
        <v-btn text class="button-style" download :href="'img/nomenclature_niv' + levelPopup + '.jpg'">Télécharger la nomenclature</v-btn>
        <v-btn text @click="closeDialog" class="button-style">Fermer</v-btn>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'DataOptn',
  props: {
    swipeIsActivated: {
      type: Boolean
    }
  },
  data () {
    return {
      dialog: false,
      // Niveau de nomenclature
      level: 1,
      levelPopup: 1,
      // Valeur d'opacité de la couche
      slider: 100,
      slider2: 100
    }
  },
  methods: {
    closeDialog () {
      this.dialog = false
    },
    displayPopupLegend (level) {
      this.dialog = true
      this.levelPopup = level
    }
  },
  watch: {
    // Lorsque le niveau de nomenclature est modifié, envoi un évènement pour appliquer les modifications
    level (newVal) {
      this.$emit('changeLevel', newVal)
    },
    // Lorsque la valeur de transparence est modifiée, envoi un évènement pour appliquer les modifications
    slider (newVal) {
      this.$emit('updateOpacity', newVal)
    },
    // Lorsque la valeur de transparence est modifiée, envoi un évènement pour appliquer les modifications
    slider2 (newVal) {
      this.$emit('updateOpacity2', newVal)
    }
  }
}
</script>

<style scoped>
.dialog-card {
  padding: 16px;
}

.slider-value {
  color: #0075ce;
  position: relative;
  bottom: 28px;
}

.button-style {
  text-transform: inherit;
}
</style>