<!-- Ce composant est le drawer de statistique présent à droite de la carte sur Explore -->
<template>
  <v-navigation-drawer
    right
    stateless
    absolute
    v-model="drawer"
    style="overflow: auto"
    width="30%"
  >
    <!-- Bouton permettant de cacher le drawer de statistiques -->
    <v-btn
      class="details-button-drawer d-print-none"
      style="border-radius: 4px"
      x-large
      tile
      outlined
      @click="toggledDrawer"
    >
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>

    <!-- Section permettant de changer la date de millésime -->
    
    <div class="explore-year-switch">
      <v-row dense style="flex-wrap: nowrap">
        <v-col>
          <v-select
            v-model="millesimeSelected"
            :items="millesimeList"
            label="Millésime"
            outlined
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            v-if="swipeActivated"
            v-model="millesimeSelected2"
            :items="millesimeList"
            label="Millésime 2"
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-btn v-if="!swipeActivated" outlined @click="activateSwipe">Comparer les millésimes</v-btn>
      <v-btn v-if="swipeActivated" outlined @click="activateSwipe">Stopper la comparaison de millésimes</v-btn>
    </div>

    <!-- Statistiques de la Région à afficher lorsqu'aucune ville ou entité administrative est sélectionnée -->
    <div class="details-div-search" v-if="!elementSelected">
      <!-- Nom de l'entité -->
      <div class="details-title">Région Grand Est</div>
      <!-- Surface de l'entité -->
      <div class="details-subtitle">
        <span style="color: black">Surface totale du territoire : </span> {{ formatNumber(Math.round(this.cityArea / 1000000)) }} km2
      </div>
      <div class="details-subtitle">
        <span style="color: black">Population : </span> {{ formatNumber(5556219) }} habitants en 2019 
      </div>
      <!-- Millésimes exacts de la donnée affichée -->
      <div class="details-subtitle">
        <p class="mb-0" style="color: black">Millésime 1 : 2018-2019</p>
        <p class="mb-0" style="color: black">Millésime 2 : 2007 à 2010</p>
      </div>

      <v-row dense>
        <v-col>
          <v-btn class="ma-1" style="text-transform: inherit" @click="dialog = true" color="#4473c5" elevation="0" dark>
            Analyses avancées
          </v-btn>
        </v-col>
        <v-col>
          <v-tooltip content-class="custom-tooltip" top color="#4473c5">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="elevation-0"
                fab
                dark
                small
                color="#4473c5"
                href="https://www.datagrandest.fr/portail/fr/projets/occupation-du-sol#pdf"
                target="_blank"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon dark>
                  mdi-download
                </v-icon>
              </v-btn>
            </template>
            <span>Portraits de territoire et donnée OCS GE 2</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <!-- Grille de statistiques -->
      <MainStat :items="items" :year1="'2018-2019'" :year2="'2007-2010'" v-if="this.displayValue === 0"/>
      <!-- <div class="details-grid" v-if="this.displayValue === 0"> -->
        <!-- Boucle sur chaque élément pour afficher des statistiques -->
        <!-- <div class="details-grid-item" v-for="(item, i) in items" :key="i">
          <v-container>
            <div style="text-align: center">
              <img :src="require('@/assets/images/explore_logo/' + item.img)" style="max-height: 60px; max-width: 60px">
            </div>
            <div class="details-grid-item-description" style="font-weight: bold">
              {{ item.description }}
            </div>
            <div style="text-decoration: underline; text-align: center">Millésime 1</div>
            <div class="details-grid-item-number">
              {{ item.number }} {{ item.unit }} ({{ item.tx }}%)
            </div>
            <div class="details-grid-item-number2">{{ item.subNumber }} ha ({{ item.subTx }}%)</div>
          </v-container>
        </div>
      </div> -->

      <!-- différents affichages pour les statistiques disponibles -->
      <Step1 v-if="displayValue === 1" :cityType="'region'" :millesime1="currentMillesime1" :millesime2="currentMillesime2"></Step1>
      <Step2 v-if="displayValue === 2" :cityType="'region'" :millesime1="currentMillesime1" :millesime2="currentMillesime2"></Step2>
      <Step3 v-if="displayValue === 3" :cityType="'region'" :millesime1="currentMillesime1" :millesime2="currentMillesime2"></Step3>
      <Step4 v-if="displayValue === 4" :cityType="'region'" :millesime1="currentMillesime1" :millesime2="currentMillesime2"></Step4>
      <Step5 v-if="displayValue === 5" :cityType="'region'" :millesime1="currentMillesime1" :millesime2="currentMillesime2"></Step5>
      <Step6 v-if="displayValue === 6" :cityType="'region'" :millesime1="currentMillesime1" :millesime2="currentMillesime2"></Step6>

      <!--  Section des différents boutons -->
      <div class="text-center d-print-none">
        <!-- <v-btn class="ma-1" rounded @click="dialog = true" color="#2596be" elevation="0" dark
          >Analyses avancées</v-btn
        > -->
        <v-btn class="ma-1" color="#4473c5" dark elevation="0" style="text-transform: inherit" href="https://www.datagrandest.fr/portail/fr/projets/occupation-du-sol#pdf" target="__blank">
          Portrait de territoire
        </v-btn>
        <v-btn download href="OCSGE2_description_indicateurs_plateforme_version2_avril2022.pdf" class="ma-1" color="#4473c5" dark elevation="0" style="text-transform: inherit">Méthodes de calcul</v-btn>
      </div>

      <!-- Dialog that is opened when the "Analyse avancée" button is clicked. -->
      <AnalyseDialog
        :dialog="dialog"
        :currentCity="currentCity"
        @updateDialog="setDisplay"
      ></AnalyseDialog>

      <!-- Crédits -->
      <v-subheader class="mt-3">
        © OpenStreetMap contributors.
      </v-subheader>
      <v-subheader>
        <div>Sources : <a target="__blank" href="https://www.datagrandest.fr/geonetwork/srv/fre/catalog.search#/search?resultType=details&sortBy=relevance&fast=index&_content_type=json&from=1&to=20&any=OCSGE2">Catalogue DataGrandEst</a>, <a href="https://www.datagrandest.fr/geonetwork/srv/fre/catalog.search#/metadata/FR-200052264-Commune_Grand_Est" target="__blank">Communes et délimitations administratives du Grand Est</a>.</div>
      </v-subheader>
    </div>
    <!-- Affiche les statistiques lorsqu'une entité administrative est sélectionnée -->
    <div class="details-div" v-if="elementSelected">
      <!-- Nom de l'entité -->
      <div class="details-title">{{ this.cityName }}</div>
      <!-- Département de l'entité -->
      <div class="details-subtitle">
        <span style="color: black">Département : </span>{{ this.cityCounty }}
      </div>
      <!-- Surface de l'entité -->
      <div class="details-subtitle">
        <span style="color: black">Surface totale du territoire : </span> {{ formatNumber(Math.round(this.cityArea / 1000000)) }} km2
      </div>
      <!-- Population de l'entité -->
      <div class="details-subtitle" v-if="cityPopulation !== null">
        <span style="color: black">Population : </span> {{ formatNumber(cityPopulation.pmun) }} habitants en {{ cityPopulation.millesime_rp }}
      </div>
      <!-- Millésimes exacts de la donnée affichée -->
      <div class="details-subtitle">
        <span style="color: black">Millésimes : </span> {{ this.cityYear }}
      </div>
      <!-- Si l'entité n'est pas une commune, affiche un bouton permettant de retourner vers la commune initiale -->
      <!-- <v-btn v-if="!displaySuggestion" small outlined @click="updateCity(cityHistorized)"><v-icon>mdi-arrow-left</v-icon> Retourner vers la commune</v-btn> -->
      <!-- Si l'entité est une commune, affiche les autres entités en relation disponibles -->
      <!-- <div class="details-subtitle d-print-none" v-if="displaySuggestion" style="margin-top: 3%">
        <span style="color: grey;">Echelles d'analyse disponibles :</span>
      </div> -->
      <!-- <Suggestion class="d-print-none" @updateScaleStat="updateScale" @changeSuggestion="changeScale" @changeCityType="updateCityType" :cityType="cityType" :epci="linked_element.epci" :pnr="linked_element.pnr" :scot="linked_element.scot" :dpt="linked_element.dpt"></Suggestion> -->
      <v-row dense>
        <v-col>
          <v-btn class="ma-1" @click="dialog = true" style="text-transform: inherit" color="#4473c5" elevation="0" dark>
            Analyses avancées
          </v-btn>
        </v-col>
        <v-col>
          <v-tooltip content-class="custom-tooltip" top color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="elevation-0"
                fab
                dark
                small
                color="#4473c5"
                href="https://www.datagrandest.fr/portail/fr/projets/occupation-du-sol#pdf"
                target="_blank"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon dark>
                  mdi-download
                </v-icon>
              </v-btn>
            </template>
            <span>Portraits de territoire et donnée OCS GE 2</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <!-- Grille de statistiques -->
      <MainStat :items="items" :year1="currentMillesime1" :year2="currentMillesime2" v-if="this.displayValue === 0"/>
      <!-- <div class="details-grid" v-if="this.displayValue === 0"> -->
        <!-- Boucle sur chaque élément pour afficher des statistiques -->
        <!-- <div class="details-grid-item" v-for="(item, i) in items" :key="i">
          <v-container>
            <div class="details-grid-item-description" style="font-weight: bold">
              {{ item.description }}
            </div>
            <div style="text-align: center">
              <img :src="require('@/assets/images/explore_logo/' + item.img)" style="max-height: 60px; max-width: 60px">
            </div> -->
            <!-- <v-avatar style="position: relative; left: 32%; right: 32%" size="60px" color="grey"></v-avatar> -->
            <!-- <div class="details-grid-item-number">
              {{ item.number }} {{ item.unit }} ({{ item.tx }}%)
            </div>
            <div class="details-grid-item-number2">{{ item.subNumber }} ha ({{ item.subTx }}%)</div>
          </v-container>
        </div>
      </div> -->

      <!-- différents affichages pour les statistiques disponibles -->
      <Step1 v-if="displayValue === 1" :city="currentCity" :cityType="cityType" :millesime1="currentMillesime1" :millesime2="currentMillesime2"></Step1>
      <Step2 v-if="displayValue === 2" :city="currentCity" :cityType="cityType" :millesime1="currentMillesime1" :millesime2="currentMillesime2"></Step2>
      <Step3 v-if="displayValue === 3" :city="currentCity" :cityType="cityType" :millesime1="currentMillesime1" :millesime2="currentMillesime2"></Step3>
      <Step4 v-if="displayValue === 4" :city="currentCity" :cityType="cityType" :millesime1="currentMillesime1" :millesime2="currentMillesime2"></Step4>
      <Step5 v-if="displayValue === 5" :city="currentCity" :cityType="cityType" :millesime1="currentMillesime1" :millesime2="currentMillesime2"></Step5>
      <Step6 v-if="displayValue === 6" :city="currentCity" :cityType="cityType" :millesime1="currentMillesime1" :millesime2="currentMillesime2"></Step6>

      <!--  Section des différents boutons -->
      <div class="text-center d-print-none">
        <v-btn class="ma-1" color="#4473c5" dark elevation="0" style="text-transform: inherit" href="https://www.datagrandest.fr/portail/fr/projets/occupation-du-sol#pdf" target="__blank">
          Portrait de territoire
        </v-btn>
        <v-btn download href="OCSGE2_description_indicateurs_plateforme_version2_avril2022.pdf" class="ma-1" color="#4473c5" dark elevation="0" style="text-transform: inherit">Méthodes de calcul</v-btn>
      </div>

      <!-- Dialog that is opened when the "Analyse avancée" button is clicked. -->
      <AnalyseDialog
        :dialog="dialog"
        :currentCity="currentCity"
        @updateDialog="setDisplay"
      ></AnalyseDialog>

      <!-- Crédits -->
      <v-subheader class="mt-3">
        © OpenStreetMap contributors.
      </v-subheader>
      <v-subheader>
        <div>Sources : <a target="__blank" href="https://www.datagrandest.fr/geonetwork/srv/fre/catalog.search#/search?resultType=details&sortBy=relevance&fast=index&_content_type=json&from=1&to=20&any=OCSGE2">Catalogue DataGrandEst</a>, <a href="https://www.datagrandest.fr/geonetwork/srv/fre/catalog.search#/metadata/FR-200052264-Commune_Grand_Est" target="__blank">Communes et délimitations administratives du Grand Est</a>.</div>
      </v-subheader>
    </div>
  </v-navigation-drawer>
</template>

<script>
import AnalyseDialog from "@/components/Explore/AnalyseDialog";
import Step1 from "@/components/Explore/steppers/Step1";
import Step2 from "@/components/Explore/steppers/Step2";
import Step3 from "@/components/Explore/steppers/Step3";
import Step4 from "@/components/Explore/steppers/Step4";
import Step5 from "@/components/Explore/steppers/Step5";
import Step6 from "@/components/Explore/steppers/Step6";
import MainStat from "@/components/Explore/MainStat"
import { transformExtent } from "ol/proj";
import proj4 from 'proj4';
import {register} from 'ol/proj/proj4';
import axios from 'axios'
export default {
  name: "Details",
  components: {
    AnalyseDialog,
    MainStat,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
  },
  props: {
    cityType: {
      type: String
    },
    cityHistorized: {
      type: Object
    },
    currentCity: {
      type: Object,
    },
    currentCityStat: {
      type: Object
    },
    cityPopulation: {
      type: Object || null
    },
    drawer: {
      type: Boolean,
      required: true,
    },
    elementSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
    map: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  data: function () {
    return {
      cityName: "",
      cityCounty: "",
      cityArea: 57705 * 1000000,
      cityYear: "",
      currentRegionStat: null,
      dialog: false,
      displayValue: 0,
      items: null,
      linked_element: {},
      millesimeList: ["2019", "2010"],
      millesimeSelected: "2019",
      millesimeSelected2: "2010",
      currentMillesime1: "2018-2019",
      currentMillesime2: "2007-2010",
      search: "",
      swipeActivated: false
    };
  },
  computed: {
    // Affiche ou non les autres entités administratives disponibles
    displaySuggestion () {
      return Object.keys(this.linked_element).length !== 0
    }
  },
  mounted () {
    if (!this.elementSelected) {
      this.initRegionStat()
    }
  },
  methods: {
    activateSwipe () {
      if(!this.swipeActivated) {
        this.$emit('activate-swipe')
      }  else {
        this.$emit('disable-swipe')
      }
      this.swipeActivated = !this.swipeActivated
    },
    // Change l'entité administrative sélectionnée
    changeScale (value) {
      this.linked_element = {}
      var self = this
      var data
      // Si l'utilisateur à sélectionné sur un epci
      if (value.epci_nom_complet) {
        // Emet un évènement indiquant que nous avons sélectionné un EPCI
        this.$emit('changeMapStyle', 'epci')
        this.cityName = value.epci_nom_complet
        data = { 'id_epci': value.id_epci }
        // Récupère la bounding box de l'epci sélectionné 
        axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/epciextent', data)
        .then(function (response) {
          // Filtre ce que nous souhaitons récupéré de la requête
          var regExp = /\(([^)]+)\)/;
          var result = regExp.exec(response.data);
          var stringRes = result[1].split(/[ ,]+/).join(',')
          var stringExtent = '[' + stringRes + ']'
          var value = JSON.parse(stringExtent)
          // Transforme ce que nous avons dans la bonne projection pour l'adapter à notre carte
          proj4.defs("EPSG:2154","+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
          register(proj4)
          // Adapte la carte à la vue passée
          self.map.getView().fit(transformExtent(value, 'EPSG:2154', 'EPSG:3857'))
        })
        // Récupère la surface de notre epci
        axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/epciarea', data)
        .then(function (response) {
          self.cityArea = response.data
        })
        // Récupère diverses informations sur l'epci sélectionné
        data = { 'code_admin': parseInt(value.id_epci, 10).toString() }
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/infoparticularepci', data)
        .then(function (response) {
          self.cityCounty = response.data.nom_dep
          self.cityYear = response.data.annee_nm1 + "-" + response.data.annee_n
          self.currentMillesime1 = response.data.annee_n
          self.currentMillesime2 = response.data.annee_nm1
        })
      // Sinon si l'entité sélectionnée est un SCOT (voir ci-dessus pour les détails)
      } else if (value.scot_nom_complet) {
        this.$emit('changeMapStyle', 'scot')
        this.cityName = value.scot_nom_complet
        data = { 'id_scot': value.id_scot }

        axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/scotextent', data)
        .then(function (response) {
          var regExp = /\(([^)]+)\)/;
          var result = regExp.exec(response.data);
          var stringRes = result[1].split(/[ ,]+/).join(',')
          var stringExtent = '[' + stringRes + ']'
          var value = JSON.parse(stringExtent)
          proj4.defs("EPSG:2154","+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
          register(proj4)
          self.map.getView().fit(transformExtent(value, 'EPSG:2154', 'EPSG:3857'))
        })
        axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/scotarea', data)
        .then(function (response) {
          self.cityArea = response.data
        })
        data = { 'code_admin': value.id_scot.toString() }
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/infoparticularscot', data)
        .then(function (response) {
          self.cityCounty = response.data.nom_dep
          self.cityYear = response.data.annee_nm1 + "-" + response.data.annee_n
          self.currentMillesime1 = response.data.annee_n
          self.currentMillesime2 = response.data.annee_nm1
        })
      // Sinon si l'entité sélectionnée est un Département (voir ci-dessus pour les détails)
      } else if (value.nom_dept) {
        this.$emit('changeMapStyle', 'dpt')
        this.cityName = value.nom_dept
        data = { 'code_dept': value.code_dept }
        axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/departementextent', data)
        .then(function (response) {
          var regExp = /\(([^)]+)\)/;
          var result = regExp.exec(response.data);
          var stringRes = result[1].split(/[ ,]+/).join(',')
          var stringExtent = '[' + stringRes + ']'
          var value = JSON.parse(stringExtent)
          proj4.defs("EPSG:2154","+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
          register(proj4)
          self.map.getView().fit(transformExtent(value, 'EPSG:2154', 'EPSG:3857'))
        })
        axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/departementarea', data)
        .then(function (response) {
          self.cityArea = response.data
        })
        data = { 'code_admin': parseInt(value.code_dept, 10).toString() }
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/infoparticulardepartements', data)
        .then(function (response) {
          self.cityCounty = response.data.nom_dep
          self.cityYear = response.data.annee_nm1 + "-" + response.data.annee_n
          self.currentMillesime1 = response.data.annee_n
          self.currentMillesime2 = response.data.annee_nm1
        })
      // Sinon si l'entité sélectionnée est un PNR (voir ci-dessus pour les détails)
      } else if (value.nompnr_court) {
        this.$emit('changeMapStyle', 'pnr')
        this.cityName = value.nompnr_court
        data = { 'id_pnr': value.id_pnr }
        axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/pnrextent', data)
        .then(function (response) {
          var regExp = /\(([^)]+)\)/;
          var result = regExp.exec(response.data);
          var stringRes = result[1].split(/[ ,]+/).join(',')
          var stringExtent = '[' + stringRes + ']'
          var value = JSON.parse(stringExtent)
          proj4.defs("EPSG:2154","+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
          register(proj4)
          self.map.getView().fit(transformExtent(value, 'EPSG:2154', 'EPSG:3857'))
        })
        axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/pnrarea', data)
        .then(function (response) {
          self.cityArea = response.data
        })
        data = { 'code_admin': parseInt(value.id_pnr, 10).toString() }
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/infoparticularpnr', data)
        .then(function (response) {
          self.cityCounty = response.data.nom_dep
          self.cityYear = response.data.annee_nm1 + "-" + response.data.annee_n
          self.currentMillesime1 = response.data.annee_n
          self.currentMillesime2 = response.data.annee_nm1
        })
      }
    },
    formatNumber (value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    // Lance l'initialisation du composant avec les statistiques de la région si aucun élément n'est sélectionné
    initRegionStat () {
      var self = this
      var data_stat = {code_admin: "44"}
      axios.post(process.env.VUE_APP_API_URL + "indicateurviewer/particularregionstat", data_stat)
      .then(function (result) {
        self.currentRegionStat = result.data;
      });
    },
    particularDeptStat (element) {
      var data = { 'code_dept': element.id }
      var self = this
      axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/particulardepartement', data)
      .then(function (response) {
        data = { 'code_dept': response.data.code_dept }
        // Récupère la bounding box de l'epci sélectionné 
        axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/departementextent', data)
        .then(function (response) {
          // Filtre ce que nous souhaitons récupéré de la requête
          var regExp = /\(([^)]+)\)/;
          var result = regExp.exec(response.data);
          var stringRes = result[1].split(/[ ,]+/).join(',')
          var stringExtent = '[' + stringRes + ']'
          var value = JSON.parse(stringExtent)
          // Transforme ce que nous avons dans la bonne projection pour l'adapter à notre carte
          proj4.defs("EPSG:2154","+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
          register(proj4)
          // Adapte la carte à la vue passée
          self.map.getView().fit(transformExtent(value, 'EPSG:2154', 'EPSG:3857'))
          self.cityName = element.nom
          self.$emit('changeMapStyle', 'dept')
        })
        // Récupère la surface de notre epci
        axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/departementarea', data)
        .then(function (response) {
          self.cityArea = response.data
        })
        // Récupère diverses informations sur l'epci sélectionné
        data = { 'code_admin': parseInt(response.data.code_dept, 10).toString() }
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/infoparticulardepartements', data)
        .then(function (response) {
          self.cityCounty = response.data.nom_dep
          self.cityYear = response.data.annee_nm1 + "-" + response.data.annee_n
          self.currentMillesime1 = response.data.annee_n
          self.currentMillesime2 = response.data.annee_nm1
        })
      })
      this.$emit('updateCityType', 'dept')
    },
    particularEpciStat (element) {
      var data = { 'id_epci': element.id }
      var self = this
      axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/particularepci', data)
      .then(function (response) {
        data = { 'id_epci': response.data.id_epci }
        // Récupère la bounding box de l'epci sélectionné 
        axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/epciextent', data)
        .then(function (response) {
          // Filtre ce que nous souhaitons récupéré de la requête
          var regExp = /\(([^)]+)\)/;
          var result = regExp.exec(response.data);
          var stringRes = result[1].split(/[ ,]+/).join(',')
          var stringExtent = '[' + stringRes + ']'
          var value = JSON.parse(stringExtent)
          // Transforme ce que nous avons dans la bonne projection pour l'adapter à notre carte
          proj4.defs("EPSG:2154","+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
          register(proj4)
          // Adapte la carte à la vue passée
          self.map.getView().fit(transformExtent(value, 'EPSG:2154', 'EPSG:3857'))
          self.cityName = element.nom
          self.$emit('changeMapStyle', 'epci')
        })
        // Récupère la surface de notre epci
        axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/epciarea', data)
        .then(function (response) {
          self.cityArea = response.data
        })
        // Récupère diverses informations sur l'epci sélectionné
        data = { 'code_admin': parseInt(response.data.id_epci, 10).toString() }
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/infoparticularepci', data)
        .then(function (response) {
          self.cityCounty = response.data.nom_dep
          self.cityYear = response.data.annee_nm1 + "-" + response.data.annee_n
          self.currentMillesime1 = response.data.annee_n
          self.currentMillesime2 = response.data.annee_nm1
        })
      })
      this.$emit('updateCityType', 'epci')
    },
    particularPnrStat (element) {
      var data = { 'id_pnr': element.id }
      var self = this
      axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/particularpnr', data)
      .then(function (response) {
        data = { 'id_pnr': response.data.id_pnr }
        // Récupère la bounding box de l'epci sélectionné 
        axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/pnrextent', data)
        .then(function (response) {
          // Filtre ce que nous souhaitons récupéré de la requête
          var regExp = /\(([^)]+)\)/;
          var result = regExp.exec(response.data);
          var stringRes = result[1].split(/[ ,]+/).join(',')
          var stringExtent = '[' + stringRes + ']'
          var value = JSON.parse(stringExtent)
          // Transforme ce que nous avons dans la bonne projection pour l'adapter à notre carte
          proj4.defs("EPSG:2154","+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
          register(proj4)
          // Adapte la carte à la vue passée
          self.map.getView().fit(transformExtent(value, 'EPSG:2154', 'EPSG:3857'))
          self.cityName = element.nom
          self.$emit('changeMapStyle', 'pnr')
        })
        // Récupère la surface de notre epci
        axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/pnrarea', data)
        .then(function (response) {
          self.cityArea = response.data
        })
        // Récupère diverses informations sur l'epci sélectionné
        data = { 'code_admin': parseInt(response.data.id_pnr, 10).toString() }
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/infoparticularpnr', data)
        .then(function (response) {
          self.cityCounty = response.data.nom_dep
          self.cityYear = response.data.annee_nm1 + "-" + response.data.annee_n
          self.currentMillesime1 = response.data.annee_n
          self.currentMillesime2 = response.data.annee_nm1
        })
      })
      this.$emit('updateCityType', 'pnr')
    },
    particularScotStat (element) {
      var data = { 'id_scot': element.id }
      var self = this
      axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/particularscot', data)
      .then(function (response) {
        data = { 'id_scot': response.data.id_scot }
        // Récupère la bounding box de l'epci sélectionné 
        axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/scotextent', data)
        .then(function (response) {
          // Filtre ce que nous souhaitons récupéré de la requête
          var regExp = /\(([^)]+)\)/;
          var result = regExp.exec(response.data);
          var stringRes = result[1].split(/[ ,]+/).join(',')
          var stringExtent = '[' + stringRes + ']'
          var value = JSON.parse(stringExtent)
          // Transforme ce que nous avons dans la bonne projection pour l'adapter à notre carte
          proj4.defs("EPSG:2154","+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
          register(proj4)
          // Adapte la carte à la vue passée
          self.map.getView().fit(transformExtent(value, 'EPSG:2154', 'EPSG:3857'))
          self.cityName = element.nom
          self.$emit('changeMapStyle', 'scot')
        })
        // Récupère la surface de notre epci
        axios.post(process.env.VUE_APP_API_URL + 'indicateur_ocs/scotarea', data)
        .then(function (response) {
          self.cityArea = response.data
        })
        // Récupère diverses informations sur l'epci sélectionné
        data = { 'code_admin': response.data.id_scot.toString() }
        axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/infoparticularscot', data)
        .then(function (response) {
          self.cityCounty = response.data.nom_dep
          self.cityYear = response.data.annee_nm1 + "-" + response.data.annee_n
          self.currentMillesime1 = response.data.annee_n
          self.currentMillesime2 = response.data.annee_nm1
        })
      })
      this.$emit('updateCityType', 'scot')
    },
    // Emet un évènement lorsque l'utilisateur clique sur "Imprimer"
    print () {
      this.$emit('print')
    },
    // Affiche le popup permettant de sélectionner les statistiques à afficher
    setDisplay (value) {
      this.dialog = false
      this.displayValue = value
    },
    // Emet un évènement pour cacher le drawer de statistique
    toggledDrawer() {
      this.$emit("toggledDrawer");
    },
    // Met à jour l'entité sélectionnée (permet de retourner à la commune précédemment sélectionnée)
    updateCity(city) {
      var self = this
      // Emet un évènement pour revenir vers les communes
      this.$emit('updateCityType', 'commune')
      this.currentCity = city;
      this.linked_element = {}
      // Récupère les entité administratives liées à la commune
      this.linked_element.epci = this.currentCity.id_epci
      this.linked_element.pnr = this.currentCity.id_pnr
      this.linked_element.scot = this.currentCity.id_scot
      this.linked_element.dpt = this.currentCity.code_dept
      this.cityName = city.nom;

      // Récupère les informations pour la commune sélectionnée
      var data = { 'code_admin': city.id }
      axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/infoparticularcommunes', data)
      .then(function (response) {
        self.cityCounty = response.data.nom_dep
        self.cityYear = response.data.annee_nm1 + "-" + response.data.annee_n
        self.currentMillesime1 = response.data.annee_n
        self.currentMillesime2 = response.data.annee_nm1
      })

      // Récupère la surface de l'entité administrative 
      this.$emit("citySelected", this.currentCity);
      data = { 'objectid': city.id }
      axios.post(process.env.VUE_APP_API_URL + 'communearea', data)
      .then(function (response) {
        self.cityArea = response.data
      })
    },
    // Emet un évènement mettant à jour le type de l'entité administrative sélectionnée
    updateCityType (city) {
      this.$emit('updateCityType', city)
    },
    // Change l'entité administrative sélectionnée
    updateScale (value) {
      this.$emit('updateScale', value)
    }
  },
  watch: {
    // Lorsque currentCity est modifié
    currentCity(newVal) {
      if (newVal.niveau === 'commune') {
        // Si currentCity n'est pas undefined
        if (newVal !== undefined) {
          this.cityName = newVal.nom;
          // Récupère les entités administratives liées
          this.linked_element = {}
          this.linked_element.epci = this.currentCity.id_epci
          this.linked_element.pnr = this.currentCity.id_pnr
          this.linked_element.scot = this.currentCity.id_scot
          this.linked_element.dpt = this.currentCity.code_dept
          // Récupère certaines informations pour infoparticularcommunes
          var data = { 'code_admin': this.currentCity.id }
          axios.post(process.env.VUE_APP_API_URL + 'indicateurviewer/infoparticularcommunes', data)
          .then(function (response) {
            self.cityCounty = response.data.nom_dep
            self.cityYear = response.data.annee_nm1 + "-" + response.data.annee_n
            self.currentMillesime1 = response.data.annee_n
            self.currentMillesime2 = response.data.annee_nm1
          })
          // Récupère la surface de l'entité sélectionnée
          data = { 'insee_com': this.currentCity.id }
          var self = this
          axios.post(process.env.VUE_APP_API_URL + 'inseecommunearea', data)
          .then(function (response) {
            self.cityArea = response.data
          })
        }
      } else if (newVal.niveau === 'epci') {
        this.particularEpciStat(newVal)
      } else if (newVal.niveau === 'scot') {
        this.particularScotStat(newVal)
      } else if (newVal.niveau === 'pnr') {
        this.particularPnrStat(newVal)
      } else if (newVal.niveau === 'departement') {
        this.particularDeptStat(newVal)
      }
    },
    // Garde constamment les statistiques à jour
    currentCityStat () {
      this.items = [
        {
          number: this.currentCityStat.arti_s_19,
          unit: "ha",
          tx: this.currentCityStat.arti_tx_19,
          subNumber: this.currentCityStat.arti_evo,
          subTx: this.currentCityStat.arti_var,
          description: "Artificialisé",
          img: "$vuetify.icon.artificialise",
          color: "#932bc9",
          // img: "forest_color.png",
        },
        {
          number: this.currentCityStat.imp_bati_s_19,
          unit: "ha",
          tx: this.currentCityStat.imp_bati_tx_19,
          subNumber: this.currentCityStat.imp_bati_evo,
          subTx: this.currentCityStat.imp_bati_var,
          description: "Imperméable bâti",
          img: "$vuetify.icon.impermeable_bati",
          color: "#f45b25",
        },
        {
          number: this.currentCityStat.imp_non_bati_s_19,
          unit: "ha",
          tx: this.currentCityStat.imp_non_bati_tx_19,
          subNumber: this.currentCityStat.imp_non_bati_evo,
          subTx: this.currentCityStat.imp_non_bati_var,
          description: "Imperméable non bâti",
          img: "$vuetify.icon.impermeable_non_bati",
          color: "#ff9292",
        },
        {
          number: this.currentCityStat.perm_s_19,
          unit: "ha",
          tx: this.currentCityStat.perm_tx_19,
          subNumber: this.currentCityStat.perm_evo,
          subTx: this.currentCityStat.perm_var,
          description: "Perméable urbain",
          img: "$vuetify.icon.permeable_urbain",
          color: "#96af38",
        },
        {
          number: this.currentCityStat.agri_s_19,
          unit: "ha",
          tx: this.currentCityStat.agri_tx_19,
          subNumber: this.currentCityStat.agri_evo,
          subTx: this.currentCityStat.agri_var,
          description: "Agricole",
          img: "$vuetify.icon.agricole",
          color: "#e0a500",
        },
        {
          number: this.currentCityStat.foret_s_19,
          unit: "ha",
          tx: this.currentCityStat.foret_tx_19,
          subNumber: this.currentCityStat.foret_evo,
          subTx: this.currentCityStat.foret_var,
          description: "Forestier et semi-naturel",
          img: "$vuetify.icon.forestier",
          color: "#059e43",
        },
        {
          number: this.currentCityStat.zh_s_19,
          unit: "ha",
          tx: this.currentCityStat.zh_tx_19,
          subNumber: this.currentCityStat.zh_evo,
          subTx: this.currentCityStat.zh_var,
          description: "Zone humide",
          img: "$vuetify.icon.zone_humide",
          color: "#2cc4c4",
        },
        {
          number: this.currentCityStat.eau_s_19,
          unit: "ha",
          tx: this.currentCityStat.eau_tx_19,
          subNumber: this.currentCityStat.eau_evo,
          subTx: this.currentCityStat.eau_var,
          description: "Eau",
          img: "$vuetify.icon.eau",
          color: "#2497ff",
        }
      ]
    },
    currentRegionStat () {
      this.items = [
        {
          number: this.currentRegionStat.arti_s_19,
          unit: "ha",
          tx: this.currentRegionStat.arti_tx_19,
          subNumber: this.currentRegionStat.arti_evo,
          subTx: this.currentRegionStat.arti_var,
          description: "Artificialisé",
          img: "$vuetify.icon.artificialise",
          color: "#932bc9",
          // img: "forest_color.png",
        },
        {
          number: this.currentRegionStat.imp_bati_s_19,
          unit: "ha",
          tx: this.currentRegionStat.imp_bati_tx_19,
          subNumber: this.currentRegionStat.imp_bati_evo,
          subTx: this.currentRegionStat.imp_bati_var,
          description: "Imperméable bâti",
          img: "$vuetify.icon.impermeable_bati",
          color: "#f45b25",
        },
        {
          number: this.currentRegionStat.imp_non_bati_s_19,
          unit: "ha",
          tx: this.currentRegionStat.imp_non_bati_tx_19,
          subNumber: this.currentRegionStat.imp_non_bati_evo,
          subTx: this.currentRegionStat.imp_non_bati_var,
          description: "Imperméable non bâti",
          img: "$vuetify.icon.impermeable_non_bati",
          color: "#ff9292",
        },
        {
          number: this.currentRegionStat.perm_s_19,
          unit: "ha",
          tx: this.currentRegionStat.perm_tx_19,
          subNumber: this.currentRegionStat.perm_evo,
          subTx: this.currentRegionStat.perm_var,
          description: "Perméable urbain",
          img: "$vuetify.icon.permeable_urbain",
          color: "#96af38",
        },
        {
          number: this.currentRegionStat.agri_s_19,
          unit: "ha",
          tx: this.currentRegionStat.agri_tx_19,
          subNumber: this.currentRegionStat.agri_evo,
          subTx: this.currentRegionStat.agri_var,
          description: "Agricole",
          img: "$vuetify.icon.agricole",
          color: "#e0a500",
        },
        {
          number: this.currentRegionStat.foret_s_19,
          unit: "ha",
          tx: this.currentRegionStat.foret_tx_19,
          subNumber: this.currentRegionStat.foret_evo,
          subTx: this.currentRegionStat.foret_var,
          description: "Forestier et semi-naturel",
          img: "$vuetify.icon.forestier",
          color: "#059e43",
        },
        {
          number: this.currentRegionStat.zh_s_19,
          unit: "ha",
          tx: this.currentRegionStat.zh_tx_19,
          subNumber: this.currentRegionStat.zh_evo,
          subTx: this.currentRegionStat.zh_var,
          description: "Zone humide",
          img: "$vuetify.icon.zone_humide",
          color: "#2cc4c4",
        },
        {
          number: this.currentRegionStat.eau_s_19,
          unit: "ha",
          tx: this.currentRegionStat.eau_tx_19,
          subNumber: this.currentRegionStat.eau_evo,
          subTx: this.currentRegionStat.eau_var,
          description: "Eau",
          img: "$vuetify.icon.eau",
          color: "#2497ff",
        }
      ]
    },
    elementSelected(newVal) {
      if (!newVal) {
        this.initRegionStat()
        this.cityArea = 57705 * 1000000
      }
    },
    // Si le millésime change, envoi un évènement
    millesimeSelected(val) {
      this.$emit("dateChanged", val);
    },
    // Si le millésime change, envoi un évènement
    millesimeSelected2(val) {
      this.$emit("dateChanged2", val);
    },
  },
};
</script>

<style scoped>
.custom-tooltip {
  opacity: 1 !important
}

.icon-info:hover {
  cursor: pointer
}
</style>