<!-- Popup de maintenance à activer/désactiver manuellement (ne devra plus être utilisée suite à l'implémentation des 10 départements) -->
<template>
  <v-dialog max-width="70vw" persistent v-model="display">
    <v-card class="pa-5" max-width="70vw">
      <v-container>
        <!-- Affiche le logo GGE -->
        <v-row class="mb-10" align="center" justify="center">
          <v-img :src="require('@/assets/images/geograndest.svg')" :lazy-src="require('@/assets/images/geograndest.svg')" contain max-height="100px" max-width="200px"></v-img>
        </v-row>
        <!-- Texte -->
        <v-row>
          <p style="font-size: 18px">L'application est actuellement en cours de Maintenance, pouvant entraîner des dysfonctionnements.<br/>Merci de votre compréhension et à très bientôt !</p>
        </v-row>
        <!-- Affiche l'heure de début de la maintenance -->
        <v-row>
          <p><span style="font-weight: bold">Début : </span>{{ start }}</p>
        </v-row>
        <!-- Affiche l'estimation de l'heure de fin de la maintenance -->
        <v-row>
          <p><span style="font-weight: bold">Fin (estimation) : </span>{{ end }}</p>
        </v-row>
        <!-- Bouton fermant le popup -->
        <v-row align="center" justify="center">
          <v-btn text x-large color="green" @click="closeDrawer()">
            J'ai compris
          </v-btn>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
// Charge ce qu'à renseigné l'utilisateur
import data from "@/components/Maintenance/maintenance.json"

export default {
  name: 'maintenance',
  data () {
    return {
      // Affiche ou non le popup
      display: data.display,
      // Heure de début
      start: data.start,
      // Estimation de l'heure de fin
      end: data.end
    }
  },
  methods: {
    // Ferme le popup
    closeDrawer () {
      this.display = false
    }
  }
}
</script>
